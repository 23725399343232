import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { setIsExportSearchDialogOpen } from "./exportSearchSlice";
import axios from "../axios";
import {
    buildApiV3UrlWithQuery,
    convertFieldToString,
    fixSearchStringForPostgres,
    removeSpaces,
    fieldsValidation,
    fieldValidationTypeCsv,
    buildApiV3Url,
} from "../shared/Utils";
import {
    parseSuccessResponse,
    parseGetAllObjResponse,
    parseGetObjResponse,
} from "../shared/responseUtils";
import { CRUDOperation } from "../shared/constants";
import getConfig from "../config";

const { hostnames } = getConfig();
const { docLockerClient } = hostnames;

const initialState = {
    isloading: false,
    customer: {},
    noDataType: "",
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 20,
            totalRecords: 0,
        },
    },
    formState: {
        nameOrCode: "",
        nameOrCodeWhileLoading: "",
    },
    noSearch: true,
    isLoadingAllStates: false,
    allStates: [],
    isLoadingStates: false,
    states: [],
    isLoadingShipStateOptions: false,
    shipStateOptions: [],
    isLoadingCustomerShipStates: false,
    isLoadingCustomerTaxRegions: false,
    isLoadingCustomerShipToZones: false,
    isLoadingCustomerShipTos: false,
    isLoadingCustomerBillTos: false,
    isRelatedCustomerDialog: false,
    isUnlinkCustomer: false,
    isUnlinkParent: false,
    relatedCustomer: [],
    customerShipStates: [],
    customerTaxRegions: [],
    customerShipToZones: [],
    customerShipTos: [],
    customerBillTos: [],
    customerIds: [],
    customerFiltersState: {
        isCustomerDetails: true,
        isCertificateDetails: false,
        customerRegions: [],
        isMissingEmailAddress: false,
        isMissingcertificates: false,
        missingExposureZones: [],
        isBillToRelation: false,
        isShipToRelation: false,
        isDuplicateRelation: false,
        customerCodes: [],
        customerCodeError: "",
        customerCreatedDateAfter: "",
        customerCreatedDateBefore: "",
        customerModifiedDateAfter: "",
        customerModifiedDateBefore: "",
        certificateStatus: [],
        certificateRegions: [],
        exemptReasons: [],
        isPurchaseOrder: false,
        invoiceNumber: "",
        certificateIds: "",
        certificateIdError: "",
        isCertificateImage: 0,
        certificateCreatedDateAfter: "",
        certificateCreatedDateBefore: "",
        certificateModifiedDateAfter: "",
        certificateModifiedDateBefore: "",
        certificateEffectiveDateAfter: "",
        certificateEffectiveDateBefore: "",
        certificateExpirationDateAfter: "",
        certificateExpirationDateBefore: "",
        isVendorFilter: false,
        savedSearch: "",
        customerCustomFields: {},
        alternateId: "",
        customersLabels: [],
        certificatesLabels: [],
        nameOrCode: "",
    },
    sortColumn: ["id", true],
    defaultCustomerColumns: [
        { id: "address", label: "Address", hidden: false },
        { id: "activeCertificateCount", label: "Active certificates", hidden: false },
        { id: "alternateId", label: "Alternate ID", hidden: true, index: 2 },
        { id: "phoneNumber", label: "Phone", hidden: true },
        { id: "faxNumber", label: "Fax", hidden: true },
        { id: "emailAddress", label: "Email", hidden: true },
        { id: "contactName", label: "Contact name", hidden: true },
        { id: "created", label: "Created", hidden: true },
        { id: "modified", label: "Last modified", hidden: true },
        { id: "customerRelationship", label: "Bill-to and ship-to relationships", hidden: true },
        { id: "parentCustomerNumber", label: "Bill-to customer number", hidden: true, index: 10 },
        { id: "parentCustomerName", label: "Bill-to customer name", hidden: true, index: 11 },
        { id: "parentCustomerAddress", label: "Bill-to customer address", hidden: true, index: 12 },
    ],

    defaultVendorColumns: [
        { id: "address", label: "Address", hidden: false },
        { id: "activeCertificateCount", label: "Active certificates", hidden: false },
        { id: "alternateId", label: "Alternate ID", hidden: true },
        { id: "phoneNumber", label: "Phone", hidden: true },
        { id: "faxNumber", label: "Fax", hidden: true },
        { id: "emailAddress", label: "Email", hidden: true },
        { id: "contactName", label: "Contact name", hidden: true },
        { id: "created", label: "Created", hidden: true },
        { id: "modified", label: "Last modified", hidden: true },
        { id: "id", label: "Vendor ID", hidden: true },
    ],

    isDeletingCustomer: false,
    isCustomerMissingCertificates: false,
    isLoadingMissingCertRegion: false,
    customerMissingCertRegions: [],
    isLoadingCustomerExport: false,
    isLoadingCustomerCustomFields: false,
    isLoadingCustomFields: false,
    customFields: [],
    customerCustomFields: [],
    isBulkDeleteCustomerDialogOpen: false,
    isLoadingCustomerColumns: false,
    labels: [],
    customerLabels: [],
    isLoadingCustomerLabels: false,
    customerExistingCustomFieldValues: {},
    customerAddedCustomFieldValues: {},
    formUpdated: false,
    shipFromStates: [],
    isLoadingShipFromStates: false,
    shipToStates: [],
    isLoadingShipToStates: false,
    isCustomerCountAPICompleted: 0,
    isLoadingCustomerCount: false,
};

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setCustomer: (state, action) => {
            if (action.payload) {
                if (action.payload?.state?.id)
                    action.payload.state.id = action.payload.state?.id?.toString();
                if (action.payload?.country?.id)
                    action.payload.country.id = action.payload.country?.id?.toString();
            }
            state.customer = action.payload;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setHasCustomer: (state, action) => {
            state.isHasCustomer = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: (state, action) => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: action?.payload || initialState.page.rowsPerPage,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setNoSearch: (state, action) => {
            state.noSearch = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setLoadingAllStates: (state, action) => {
            state.isLoadingAllStates = action.payload;
        },
        setAllStates: (state, action) => {
            state.allStates = action.payload;
        },
        setLoadingStates: (state, action) => {
            state.isLoadingStates = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setLoadingShipStateOptions: (state, action) => {
            state.isLoadingShipStateOptions = action.payload;
        },
        setShipStateOptions: (state, action) => {
            state.shipStateOptions = action.payload;
        },
        setLoadingCustomerShipStates: (state, action) => {
            state.isLoadingCustomerShipStates = action.payload;
        },
        setLoadingCustomerTaxRegions: (state, action) => {
            state.isLoadingCustomerTaxRegions = action.payload;
        },
        setLoadingCustomerShipToZones: (state, action) => {
            state.isLoadingCustomerShipToZones = action.payload;
        },
        setIsLoadingCustomerShipTos: (state, action) => {
            state.isLoadingCustomerShipTos = action.payload;
        },
        setIsLoadingCustomerBillTos: (state, action) => {
            state.isLoadingCustomerBillTos = action.payload;
        },
        setCustomerShipStates: (state, action) => {
            state.customerShipStates = action.payload;
        },
        setCustomerTaxRegions: (state, action) => {
            state.customerTaxRegions = action.payload;
        },
        setCustomerShipToZones: (state, action) => {
            state.customerShipToZones = action.payload;
        },
        setCustomerShipTos: (state, action) => {
            state.customerShipTos = action.payload;
        },
        setCustomerBillTos: (state, action) => {
            state.customerBillTos = action.payload;
        },
        setCustomerIds: (state, action) => {
            state.customerIds = action.payload;
        },
        setDefaultCustomerColumns: (state, action) => {
            state.defaultCustomerColumns = action.payload;
        },
        setDefaultVendorColumns: (state, action) => {
            state.defaultVendorColumns = action.payload;
        },
        setCustomerFiltersState: (state, action) => {
            const key = Object.keys(action.payload)[0];
            const value = Object.values(action.payload)[0];
            state.customerFiltersState[key] = value;
        },
        clearCustomerFiltersState: state => {
            state.customerFiltersState = initialState.customerFiltersState;
        },
        setDeletingCustomer: (state, action) => {
            state.isDeletingCustomer = action.payload;
        },
        setSavedSearch: (state, action) => {
            state.customerFiltersState.savedSearch = action.payload;
        },
        setSavedSearchState: (state, action) => {
            state.customerFiltersState = {
                ...initialState.customerFiltersState,
                ...action.payload,
            };
        },
        setIsRelatedCustomerDialog: (state, action) => {
            state.isRelatedCustomerDialog = action.payload;
        },
        setIsUnlinkingCustomer: (state, action) => {
            state.isUnlinkCustomer = action.payload;
        },
        setIsUnlinkParent: (state, action) => {
            state.isUnlinkParent = action.payload;
        },
        setRelatedCustomer: (state, action) => {
            state.relatedCustomer = action.payload;
        },
        setIsCustomerMissingCertificates: (state, action) => {
            state.isCustomerMissingCertificates = action.payload;
        },
        setIsLoadingMissingCertRegion: (state, action) => {
            state.isLoadingMissingCertRegion = action.payload;
        },
        setCustomerMissingCertRegions: (state, action) => {
            state.customerMissingCertRegions = action.payload;
        },
        setIsLoadingCustomerExport: (state, action) => {
            state.isLoadingCustomerExport = action.payload;
        },
        setLoadingCustomFields: (state, action) => {
            state.isLoadingCustomFields = action.payload;
        },
        setLoadingCustomerCustomFields: (state, action) => {
            state.isLoadingCustomerCustomFields = action.payload;
        },
        setCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        setCustomerCustomFields: (state, action) => {
            state.customerCustomFields = action.payload;
        },
        setIsVendorFilter: (state, action) => {
            state.customerFiltersState.isVendorFilter = action.payload;
        },
        setIsBulkDeleteCustomerDialogOpen: (state, action) => {
            state.isBulkDeleteCustomerDialogOpen = action.payload;
        },
        setIsLoadingCustomerColumns: (state, action) => {
            state.isLoadingCustomerColumns = action.payload;
        },
        setLabels: (state, action) => {
            state.labels = action.payload;
        },
        setCustomerLabels: (state, action) => {
            state.customerLabels = action.payload;
        },
        setLoadingCustomerLabels: (state, action) => {
            state.isLoadingCustomerLabels = action.payload;
        },
        setCustomerExistingCustomFieldValues: (state, action) => {
            if (Object.keys(action.payload)[0]) {
                Object.keys(action.payload).forEach(i => {
                    state.customerExistingCustomFieldValues[i] = action.payload[i];
                });
            }
        },
        setCustomerAddedCustomFieldValues: (state, action) => {
            const key = Object.keys(action.payload)[0];
            if (action.payload[key] === null) {
                delete state.customerAddedCustomFieldValues[key];
                return;
            }
            state.customerAddedCustomFieldValues[key] = action.payload[key];
        },
        setFormUpdated: (state, action) => {
            state.formUpdated = action.payload;
        },
        setShipFromStates: (state, action) => {
            state.shipFromStates = action.payload;
        },
        setLoadingShipFromStates: (state, action) => {
            state.isLoadingShipFromStates = action.payload;
        },
        setShipToStates: (state, action) => {
            state.shipToStates = action.payload;
        },
        setLoadingShipToStates: (state, action) => {
            state.isLoadingShipToStates = action.payload;
        },
        setCustomerCountAPICompleted: (state, action) => {
            state.isCustomerCountAPICompleted = action.payload;
        },
        incrementCustomerCountAPICompleted: state => {
            state.isCustomerCountAPICompleted += 1;
        },
        decrementCustomerCountAPICompleted: state => {
            state.isCustomerCountAPICompleted -= 1;
        },
        setIsLoadingCustomerCount: (state, action) => {
            state.isLoadingCustomerCount = action.payload;
        },
    },
});

export const {
    setLoading,
    setHasCustomer,
    setCustomer,
    setPage,
    setPageToNull,
    setNoDataType,
    setNoSearch,
    setFormState,
    clearFormState,
    setSortColumn,
    setLoadingAllStates,
    setAllStates,
    setLoadingStates,
    setStates,
    setLoadingShipStateOptions,
    setShipStateOptions,
    setLoadingCustomerShipStates,
    setLoadingCustomerTaxRegions,
    setLoadingCustomerShipToZones,
    setIsLoadingCustomerShipTos,
    setIsLoadingCustomerBillTos,
    setCustomerShipStates,
    setCustomerTaxRegions,
    setCustomerShipToZones,
    setCustomerShipTos,
    setCustomerBillTos,
    setCustomerFiltersState,
    clearCustomerFiltersState,
    setDefaultCustomerColumns,
    setDefaultVendorColumns,
    setDeletingCustomer,
    setSavedSearch,
    setSavedSearchState,
    setIsCustomerMissingCertificates,
    setIsLoadingMissingCertRegion,
    setCustomerMissingCertRegions,
    setIsRelatedCustomerDialog,
    setIsUnlinkingCustomer,
    setIsUnlinkParent,
    setRelatedCustomer,
    setIsLoadingCustomerExport,
    setCustomFields,
    setCustomerCustomFields,
    setLoadingCustomFields,
    setLoadingCustomerCustomFields,
    setIsVendorFilter,
    setIsBulkDeleteCustomerDialogOpen,
    setCustomerIds,
    setIsLoadingCustomerColumns,
    setLabels,
    setCustomerLabels,
    setLoadingCustomerLabels,
    setCustomerExistingCustomFieldValues,
    setCustomerAddedCustomFieldValues,
    setFormUpdated,
    setLoadingShipFromStates,
    setShipFromStates,
    setLoadingShipToStates,
    setShipToStates,
    setCustomerCountAPICompleted,
    incrementCustomerCountAPICompleted,
    decrementCustomerCountAPICompleted,
    setIsLoadingCustomerCount,
} = customerSlice.actions;

export const selectIsLoadingCustomerExport = state => {
    return state.customer.isLoadingCustomerExport;
};

export const selectCustomerMissingCertRegions = state => {
    return state.customer.customerMissingCertRegions;
};

export const selectIsLoadingMissingCertRegion = state => {
    return state.customer.isLoadingMissingCertRegion;
};

export const selectIsCustomerMissingCertificates = state => {
    return state.customer.isCustomerMissingCertificates;
};

export const selectLoading = state => {
    return state.customer.isloading;
};

export const selectHasCustomer = state => {
    return state.customer.isHasCustomer;
};

export const selectPage = state => {
    return state.customer.page;
};

export const selectNoData = state => {
    return state.customer.noDataType;
};

export const selectNoSearch = state => {
    return state.customer.noSearch;
};

export const selectCustomer = state => {
    return state.customer.customer;
};

export const selectFormState = state => {
    return state.customer.formState;
};

export const selectSortColumn = state => {
    return state.customer.sortColumn;
};
export const selectIsLoadingAllStates = state => {
    return state.customer.isLoadingAllStates;
};
export const selectAllStates = state => {
    return state.customer.allStates;
};

export const selectIsLoadingStates = state => {
    return state.customer.selectIsLoadingStates;
};

export const selectIsLoadingShipTos = state => {
    return state.customer.isLoadingCustomerShipTos;
};

export const selectIsLoadingBillTos = state => {
    return state.customer.isLoadingCustomerBillTos;
};

export const selectStates = state => {
    return state.customer.states;
};
export const selectIsLoadingShipStateOptions = state => {
    return state.customer.isLoadingShipStateOptions;
};

export const selectShipStateSelectOptions = state => {
    return state.customer.shipStateOptions;
};

export const selectIsLoadingCustomerShipStates = state => {
    return state.customer.isLoadingCustomerShipStates;
};

export const selectIsLoadingCustomerTaxRegions = state => {
    return state.customer.isLoadingCustomerTaxRegions;
};

export const selectIsLoadingCustomerShipToZones = state => {
    return state.customer.isLoadingCustomerShipToZones;
};

export const selectCustomerShipStates = state => {
    return state.customer.customerShipStates;
};
export const selectCustomerTaxRegions = state => {
    return state.customer.customerTaxRegions;
};

export const selectCustomerShipToZones = state => {
    return state.customer.customerShipToZones;
};
export const selectCustomerShipTos = state => {
    return state.customer.customerShipTos;
};

export const selectCustomerBillTos = state => {
    return state.customer.customerBillTos;
};

export const selectCustomerFiltersState = state => {
    return state.customer.customerFiltersState;
};

export const selectRelatedCustomer = state => {
    return state.customer.relatedCustomer;
};

export const selectCustomerColumns = state => {
    return state.customer.defaultCustomerColumns;
};

export const selectVendorColumns = state => {
    return state.customer.defaultVendorColumns;
};

export const selectSavedSearchState = state => {
    return state.customer.customerFiltersState.savedSearch;
};

export const selectIsRelatedCustomerDialog = state => {
    return state.customer.isRelatedCustomerDialog;
};

export const selectIsUnlinkingCustomer = state => {
    return state.customer.isUnlinkCustomer;
};

export const selectIsUnlinkParent = state => {
    return state.customer.isUnlinkParent;
};

export const selectIsLoadingCustomerCustomFields = state => {
    return state.customer.isLoadingCustomerCustomFields;
};

export const selectCustomFields = state => {
    return state.customer.customFields;
};

export const selectCustomerCustomFields = state => {
    return state.customer.customerCustomFields;
};

export const selectIsBulkDeleteCustomerDialogOpen = state => {
    return state.customer.isBulkDeleteCustomerDialogOpen;
};
export const selectCustomerIds = state => {
    return state.customer.customerIds;
};

export const selectIsLoadingCustomerColumns = state => {
    return state.isLoadingCustomerColumns;
};

export const selectLabels = state => {
    return state.customer.labels;
};

export const selectCustomerLabels = state => {
    return state.customer.customerLabels;
};

export const selectIsLoadingCustomerLabels = state => {
    return state.customer.isLoadingCustomerLabels;
};

export const selectCustomerExistingCustomFieldValues = state => {
    return state.customer.customerExistingCustomFieldValues;
};

export const selectCustomerAddedCustomFieldValues = state => {
    return state.customer.customerAddedCustomFieldValues;
};

export const selectFormUpdated = state => {
    return state.customer.formUpdated;
};
export const selectShipFromStates = state => {
    return state.customer.shipFromStates;
};

export const selectIsShipFromStateLoading = state => {
    return state.customer.isLoadingShipFromStates;
};

export const selectShipToStates = state => {
    return state.customer.shipToStates;
};

export const selectIsShipToStateLoading = state => {
    return state.customer.isLoadingShipToStates;
};

export const selectIsCustomerCountAPICompleted = state => {
    return state.customer.isCustomerCountAPICompleted;
};

export const selectIsLoadingCustomerCount = state => {
    return state.customer.isLoadingCustomerCount;
};

export const setCustomerToStore = async (dispatch, state, response) => {
    const customerData = JSON.parse(JSON.stringify(state.customer));
    customerData.isShipTo = response.isShipTo;
    customerData.isBillTo = response.isBillTo;
    dispatch(setCustomer(customerData));
};

export const getAllStatesAsync = () => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingAllStates) {
        return false;
    }
    if (localStorage.getItem("allStates")) {
        return false;
    }
    dispatch(setLoadingAllStates(true));
    const response = await axios.get(buildApiV3Url(`states?&$orderBy=name ASC&$top=200`), {
        withCredentials: true,
    });
    if (response.data.value) {
        const transformedResponse = convertFieldToString(response.data.value, "id", ["country"]);
        dispatch(setAllStates(transformedResponse));
        localStorage.setItem("allStates", JSON.stringify(transformedResponse));
    }
    dispatch(setLoadingAllStates(false));
    return true;
};

export const selectShipStateOptions = () => async (dispatch, getState) => {
    const { customer } = getState();
    let { allStates } = customer;
    if (customer.isLoadingShipStateOptions) {
        return;
    }
    if (allStates.length === 0) {
        await dispatch(getAllStatesAsync());
    }
    dispatch(setLoadingShipStateOptions(true));
    if (localStorage.getItem("allStates")) {
        allStates = JSON.parse(localStorage.getItem("allStates"));
    }
    const shipStateOptions = [];
    for (let i = 0; i < allStates.length; i += 1) {
        if (allStates[i].country) {
            if (
                allStates[i].country.name === "United States" ||
                allStates[i].country.name === "Canada"
            ) {
                shipStateOptions.push(allStates[i]);
            }
        }
    }
    dispatch(setShipStateOptions(shipStateOptions));
    dispatch(setLoadingShipStateOptions(false));
};

export const selectShipFromStatesByCountryName = country => async (dispatch, getState) => {
    const { customer } = getState();
    let { allStates } = customer;
    if (customer.isLoadingShipFromStates) {
        return;
    }

    dispatch(setLoadingShipFromStates(true));
    if (allStates.length === 0) {
        dispatch(setLoadingAllStates(false));
        await dispatch(getAllStatesAsync());
    }
    if (localStorage.getItem("allStates")) {
        allStates = JSON.parse(localStorage.getItem("allStates"));
    }
    const stateOptions = [];
    for (let i = 0; i < allStates.length; i += 1) {
        if (allStates[i].country) {
            if (
                allStates[i].country.name === country ||
                allStates[i].country.initials === country
            ) {
                stateOptions.push(allStates[i]);
            }
        }
    }
    dispatch(setShipFromStates(stateOptions));
    dispatch(setLoadingShipFromStates(false));
};

export const selectShipToStatesByCountryName = country => async (dispatch, getState) => {
    const { customer } = getState();
    let { allStates } = customer;
    if (customer.isLoadingShipToStates) {
        return;
    }

    dispatch(setLoadingShipToStates(true));
    if (allStates.length === 0) {
        dispatch(setLoadingAllStates(false));
        await dispatch(getAllStatesAsync());
    }
    if (localStorage.getItem("allStates")) {
        allStates = JSON.parse(localStorage.getItem("allStates"));
    }
    const stateOptions = [];
    for (let i = 0; i < allStates.length; i += 1) {
        if (allStates[i].country) {
            if (
                allStates[i].country.name === country ||
                allStates[i].country.initials === country
            ) {
                stateOptions.push(allStates[i]);
            }
        }
    }
    dispatch(setShipToStates(stateOptions));
    dispatch(setLoadingShipToStates(false));
};

export const getStateByCountry = country => async (dispatch, getState) => {
    const { customer } = getState();
    let { allStates } = customer;
    if (customer.isLoadingStates) {
        return;
    }

    dispatch(setLoadingStates(true));
    if (allStates.length === 0) {
        dispatch(setLoadingAllStates(false));
        await dispatch(getAllStatesAsync());
    }
    if (localStorage.getItem("allStates")) {
        allStates = JSON.parse(localStorage.getItem("allStates"));
    }
    const stateOptions = [];
    for (let i = 0; i < allStates.length; i += 1) {
        if (allStates[i].country && allStates[i].country.id) {
            if (parseInt(allStates[i].country.id, 10) === parseInt(country, 10)) {
                stateOptions.push(allStates[i]);
            }
        }
    }
    dispatch(setStates(stateOptions));
    dispatch(setLoadingStates(false));
};

export const selectIsDeletingCustomer = state => {
    return state.customer.isDeletingCustomer;
};

export const customFieldsAPI = customerID => async (dispatch, getState) => {
    const { customer } = getState();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(customer.customerAddedCustomFieldValues)) {
        let v = value;
        if (Array.isArray(value) && value.length) {
            v = value.join("|");
        }
        axios.post(
            buildApiV3Url(`customers/${customerID}/custom-fields/${key}`),
            { value: v },
            {
                withCredentials: true,
            }
        );
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(customer.customerExistingCustomFieldValues)) {
        let v = value;
        if (Array.isArray(value)) {
            v = value.join("|");
        }
        axios.put(
            buildApiV3Url(`customers/${customerID}/custom-fields/${key}`),
            { value: v },
            {
                withCredentials: true,
            }
        );
    }
};
export const deleteCustomerById = customerId => async dispatch => {
    dispatch(setDeletingCustomer(true));
    await axios.delete(buildApiV3Url(`customers/${customerId}`), {
        withCredentials: true,
    });
    dispatch(setDeletingCustomer(false));
};

export const fetchCustomerAPI = id => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isloading) {
        return;
    }

    dispatch(setLoading(true));
    dispatch(setHasCustomer(false));
    const apiURI = buildApiV3UrlWithQuery(`customers/${id}`);
    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    if (!parseSuccessResponse(CRUDOperation.Read, response)?.recordFound) {
        dispatch(setCustomer({}));
        dispatch(setLoading(false));
    } else {
        const parseGetResponse = parseGetObjResponse(response?.data);
        const transformedCustomer = parseGetResponse?.value;
        if (transformedCustomer?.state?.id)
            transformedCustomer.state.id = transformedCustomer.state?.id?.toString();
        if (transformedCustomer?.country?.id)
            transformedCustomer.country.id = transformedCustomer.country?.id?.toString();
        if (transformedCustomer?.vendorAdditionalInfos?.costCenterId)
            transformedCustomer.vendorAdditionalInfos.costCenterId =
                transformedCustomer.vendorAdditionalInfos.costCenterId.toString();
        if (transformedCustomer?.vendorAdditionalInfos?.itemCodeId)
            transformedCustomer.vendorAdditionalInfos.itemCodeId =
                transformedCustomer.vendorAdditionalInfos.itemCodeId.toString();

        dispatch(setCustomer(parseGetResponse?.value));
        dispatch(setLoading(false));
        dispatch(setHasCustomer(true));
    }
};

export const fetchCustomersAPI =
    (pageSize, pageNumber = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isloading, formState, customerFiltersState, sortColumn } = getState().customer;
        const { rowsPerPage } = getState().session;
        pageSize = !pageSize ? rowsPerPage : pageSize;
        const { nameOrCode, nameOrCodeWhileLoading } = formState;
        if (isloading) {
            dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: nameOrCode,
                })
            );
            return;
        }
        dispatch(setLoading(true));
        let searchVal = "";
        if (nameOrCodeWhileLoading !== "") {
            searchVal = nameOrCodeWhileLoading;
        } else if (customerFiltersState?.nameOrCode !== "") {
            searchVal = customerFiltersState.nameOrCode;
        } else {
            searchVal = nameOrCode;
        }

        // make a copy of the filters state and remove saved search for comparing
        const filtersWithoutSavedSearch = { ...customerFiltersState };
        delete filtersWithoutSavedSearch.savedSearch;
        delete filtersWithoutSavedSearch.isCustomerDetails;
        delete filtersWithoutSavedSearch.isCertificateDetails;
        const initialStateWithoutSavedSearch = { ...initialState.customerFiltersState };
        delete initialStateWithoutSavedSearch.savedSearch;
        delete initialStateWithoutSavedSearch.isCustomerDetails;
        delete initialStateWithoutSavedSearch.isCertificateDetails;
        const customerIds = [];

        if (
            isEqual(filtersWithoutSavedSearch, initialStateWithoutSavedSearch) &&
            searchVal === ""
        ) {
            dispatch(setNoSearch(true));
        } else {
            dispatch(setNoSearch(false));
        }

        const requestBody = {
            pageSize,
            pageNumber,
            orderBy: sortColumn[0],
            orderByDirection: sortColumn[1] ? "DESC" : "ASC",
            includes: "",
            nameOrCode: searchVal,
            purchaseOrderNumber: customerFiltersState.invoiceNumber,
            missing:
                customerFiltersState.isVendorFilter &&
                customerFiltersState?.certificateStatus?.includes("Missing")
                    ? true
                    : customerFiltersState.isMissingcertificates,
            missingExposureZones: customerFiltersState.missingExposureZones,
            isBillTo: customerFiltersState.isBillToRelation,
            isShipTo: customerFiltersState.isShipToRelation,
            isDuplicate: customerFiltersState.isDuplicateRelation,
            certificateImageExists: customerFiltersState.isCertificateImage,
            isSingle: customerFiltersState.isPurchaseOrder,
            returnEmptyEmailRecords: customerFiltersState.isMissingEmailAddress,
            isVendor: customerFiltersState.isVendorFilter,
            customerCreatedFrom: customerFiltersState.customerCreatedDateAfter
                ? customerFiltersState.customerCreatedDateAfter
                : null,
            customerCreatedTo: customerFiltersState.customerCreatedDateBefore
                ? customerFiltersState.customerCreatedDateBefore
                : null,
            customerModifiedFrom: customerFiltersState.customerModifiedDateAfter
                ? customerFiltersState.customerModifiedDateAfter
                : null,
            customerModifiedTo: customerFiltersState.customerModifiedDateBefore
                ? customerFiltersState.customerModifiedDateBefore
                : null,
            certificateCreatedFrom: customerFiltersState.certificateCreatedDateAfter
                ? customerFiltersState.certificateCreatedDateAfter
                : null,
            certificateCreatedTo: customerFiltersState.certificateCreatedDateBefore
                ? customerFiltersState.certificateCreatedDateBefore
                : null,
            certificateModifiedFrom: customerFiltersState.certificateModifiedDateAfter
                ? customerFiltersState.certificateModifiedDateAfter
                : null,
            certificateModifiedTo: customerFiltersState.certificateModifiedDateBefore
                ? customerFiltersState.certificateModifiedDateBefore
                : null,
            certificateEffectiveFrom: customerFiltersState.certificateEffectiveDateAfter
                ? customerFiltersState.certificateEffectiveDateAfter
                : null,
            certificateEffectiveTo: customerFiltersState.certificateEffectiveDateBefore
                ? customerFiltersState.certificateEffectiveDateBefore
                : null,
            certificateExpirationFrom: customerFiltersState.certificateExpirationDateAfter
                ? customerFiltersState.certificateExpirationDateAfter
                : null,
            certificateExpirationTo: customerFiltersState.certificateExpirationDateBefore
                ? customerFiltersState.certificateExpirationDateBefore
                : null,
            certificateIds: customerFiltersState.certificateIds?.length
                ? customerFiltersState.certificateIds
                      .split("\n")
                      .map(value => fixSearchStringForPostgres(value.trim()))
                      .map(Number)
                : [],
            customerCodes: customerFiltersState.customerCodes?.length
                ? customerFiltersState.customerCodes
                      .split("\n")
                      .map(value => fixSearchStringForPostgres(value.trim()))
                : [],
            status:
                customerFiltersState.isVendorFilter &&
                customerFiltersState?.certificateStatus?.includes("Missing")
                    ? customerFiltersState?.certificateStatus?.filter(status => {
                          return status !== "Missing";
                      })
                    : customerFiltersState.certificateStatus,
            exposureZones: customerFiltersState.certificateRegions,
            customerRegions: customerFiltersState.customerRegions,
            exemptionReasons: customerFiltersState.exemptReasons,
            customerCustomFields: customerFiltersState.customerCustomFields,
            alternateId: customerFiltersState.alternateId,
            customersLabels: customerFiltersState.customersLabels,
            certificatesLabels: customerFiltersState.certificatesLabels,
        };

        axios
            .post(buildApiV3Url(`customers/search`), requestBody, {
                withCredentials: true,
            })
            .then(async response => {
                if (!parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
                    onLanding
                        ? dispatch(setNoDataType("onLanding"))
                        : dispatch(setNoDataType("onSearch"));
                    dispatch(setPageToNull(pageSize));
                    dispatch(setLoading(false));
                } else {
                    const readAllResponse = parseGetAllObjResponse(response);
                    dispatch(setNoDataType(""));
                    const { page, isCustomerCountAPICompleted } = getState().customer;
                    const pageData = { ...page.paginate };
                    const paginateData = paginateDetails
                        ? {
                              pageNumber,
                              startIndex: paginateDetails.startIndex,
                              endIndex: paginateDetails.endIndex,
                              rowsPerPage: paginateDetails.rowsPerPage,
                              totalRecords: pageData.totalRecords,
                              indeterminate: false,
                          }
                        : {
                              pageNumber,
                              startIndex: 0,
                              endIndex: 19,
                              rowsPerPage: pageSize,
                              totalRecords:
                                  isCustomerCountAPICompleted === 0 ? pageData.totalRecords : 20,
                              indeterminate: !(isCustomerCountAPICompleted === 0),
                          };
                    dispatch(
                        setPage({
                            data: readAllResponse.value,
                            paginate: paginateData,
                        })
                    );
                    dispatch(setLoading(false));
                    if (readAllResponse) {
                        readAllResponse.value?.forEach(element => {
                            customerIds.push(element.id);
                        });
                        dispatch(setCustomerIds(customerIds));
                    }
                }

                if (nameOrCodeWhileLoading !== "") {
                    await dispatch(
                        setFormState({
                            ...formState,
                            nameOrCodeWhileLoading: "",
                        })
                    );
                }
            });

        if (!paginateDetails) {
            dispatch(setIsLoadingCustomerCount(true));
            dispatch(incrementCustomerCountAPICompleted());
            axios
                .post(buildApiV3Url(`customers/search-count`), requestBody, {
                    withCredentials: true,
                })
                .then(res => {
                    dispatch(decrementCustomerCountAPICompleted());
                    const { page } = getState().customer;
                    const pageData = { ...page.paginate };
                    pageData.totalRecords = res?.data;
                    pageData.indeterminate = false;
                    dispatch(
                        setPage({
                            data: page.data,
                            paginate: pageData,
                        })
                    );
                    dispatch(setIsLoadingCustomerCount(false));
                });
        }
    };

export const getCustomerShipStatesAsync = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingCustomerShipStates) {
        return;
    }
    dispatch(setLoadingCustomerShipStates(true));
    const response = await axios.get(buildApiV3Url(`customers/${customerId}/ship-to-states`), {
        withCredentials: true,
    });
    if (response && response.data && response.data.value) {
        const transformedStates = convertFieldToString(response.data.value, "id");
        dispatch(setCustomerShipStates(transformedStates));
    }
    dispatch(setLoadingCustomerShipStates(false));
};

export const getCustomerTaxRegionsAsync = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingCustomerTaxRegions) {
        return;
    }
    dispatch(setLoadingCustomerTaxRegions(true));
    const response = await axios.get(buildApiV3Url(`customers/${customerId}/tax-regions`), {
        withCredentials: true,
    });
    if (response && response.data && response.data.value) {
        const transformedStates = convertFieldToString(response.data.value, "id");
        dispatch(setCustomerTaxRegions(transformedStates));
    }
    dispatch(setLoadingCustomerTaxRegions(false));
};
export const getCustomerMissingCertRegion = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingMissingCertRegion) {
        return false;
    }
    dispatch(setIsLoadingMissingCertRegion(true));
    const response = await axios.get(
        buildApiV3Url(`customers/${customerId}/missing-certificates`),
        {
            withCredentials: true,
        }
    );
    if (response && response.data) {
        dispatch(setIsCustomerMissingCertificates(response.data.hasMissingCertificates));
        const exposureZoneArray = response.data.missingExposureZones.sort();
        let missingExposureZones = "";
        for (let i = 0; i < exposureZoneArray.length - 1; i += 1) {
            if (i < exposureZoneArray.length - 2)
                missingExposureZones = missingExposureZones
                    .concat(exposureZoneArray[i])
                    .concat(", ");
            else
                missingExposureZones = missingExposureZones
                    .concat(exposureZoneArray[i])
                    .concat(" and ");
        }
        missingExposureZones = missingExposureZones.concat(
            exposureZoneArray[exposureZoneArray.length - 1]
        );
        dispatch(setCustomerMissingCertRegions(missingExposureZones));
    }
    dispatch(setIsLoadingMissingCertRegion(false));
    return true;
};
export const getCustomerShipToZonesAsync = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingCustomerShipToZones) {
        return;
    }
    dispatch(setLoadingCustomerShipToZones(true));
    const response = await axios.get(buildApiV3Url(`customers/${customerId}/ship-to-zones`), {
        withCredentials: true,
    });

    if (parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
        const readAllResponse = parseGetAllObjResponse(response);
        const transformedStates = convertFieldToString(readAllResponse.value, "id");
        dispatch(setCustomerShipToZones(transformedStates));
    } else {
        dispatch(setCustomerShipToZones([]));
    }
    dispatch(setLoadingCustomerShipToZones(false));
};

export const getCustomerBillTos = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingCustomerBillTos) {
        return;
    }
    dispatch(setIsLoadingCustomerBillTos(true));
    const response = await axios.get(buildApiV3Url(`customers/${customerId}/bill-to`), {
        withCredentials: true,
    });
    if (response && response.data) {
        dispatch(setCustomerBillTos(response.data));
    }
    dispatch(setIsLoadingCustomerBillTos(false));
};

export const getCustomerShipTos = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingCustomerShipTos) {
        return;
    }
    dispatch(setIsLoadingCustomerShipTos(true));
    const response = await axios.get(buildApiV3Url(`customers/${customerId}/ship-tos`), {
        withCredentials: true,
    });
    if (response && response.data) {
        dispatch(setCustomerShipTos(response.data));
    }
    dispatch(getCustomerMissingCertRegion(customerId));
    dispatch(setIsLoadingCustomerShipTos(false));
};

export const linkParentCustomerAPI =
    (customerId, parentId, showToast) => async (dispatch, getState) => {
        const { customer } = getState();
        if (customer.isLoadingCustomerBillTos) {
            return;
        }
        dispatch(setIsLoadingCustomerBillTos(true));
        await axios
            .post(
                buildApiV3Url(`customers/${customerId}/bill-to/link?parentId=${parentId}`),
                { parentId },
                {
                    withCredentials: true,
                }
            )
            .then(response => {
                if (response) {
                    dispatch(setIsLoadingCustomerBillTos(false));
                    if (response.data.errorList.length) showToast("error", response.data.errorList);
                    dispatch(getCustomerBillTos(customerId));
                    dispatch(setIsRelatedCustomerDialog(false));
                    dispatch(getCustomerMissingCertRegion(customerId));
                    dispatch(setRelatedCustomer([]));
                    if (response.data.billShipTo) {
                        setCustomerToStore(dispatch, customer, response.data.billShipTo);
                    }
                }
            });
        dispatch(setIsLoadingCustomerBillTos(false));
    };

export const linkChildCustomerAPI =
    (customerId, childCustomers, showToast) => async (dispatch, getState) => {
        const { customer } = getState();
        if (customer.isLoadingCustomerShipTos) {
            return;
        }
        dispatch(setIsLoadingCustomerShipTos(true));
        await axios
            .post(buildApiV3Url(`customers/${customerId}/ship-tos/link`), childCustomers, {
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {
                    dispatch(setIsLoadingCustomerShipTos(false));
                    if (response.data.errorList.length) showToast("error", response.data.errorList);
                    dispatch(getCustomerShipTos(customerId));
                    dispatch(getCustomerMissingCertRegion(customerId));
                    dispatch(setIsRelatedCustomerDialog(false));
                    dispatch(setRelatedCustomer([]));
                    if (response.data.billShipTo) {
                        setCustomerToStore(dispatch, customer, response.data.billShipTo);
                    }
                }
            })
            .catch(error => {
                return error;
            });
        dispatch(setIsLoadingCustomerShipTos(false));
    };

export const unlinkParentAPI = (customerId, parentId, showToast) => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isUnlinkParent) {
        return;
    }
    dispatch(setIsUnlinkParent(true));
    const response = await axios.delete(
        buildApiV3Url(`customers/${customerId}/bill-to/unlink?parentId=${parentId}`),
        { withCredentials: true }
    );
    if (response && response.data) {
        if (response.data.errorList.length) showToast("error", response.data.errorList);
        dispatch(getCustomerBillTos(customerId));
        dispatch(setIsRelatedCustomerDialog(false));
        dispatch(getCustomerMissingCertRegion(customerId));
        dispatch(setRelatedCustomer([]));
        if (response.data.billShipTo) {
            setCustomerToStore(dispatch, customer, response.data.billShipTo);
        }
    }
    dispatch(setIsUnlinkParent(false));
};

export const unlinkCustomerAPI = (customerId, childId, showToast) => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isUnlinkCustomer) {
        return;
    }
    dispatch(setIsUnlinkingCustomer(true));
    const response = await axios.delete(
        buildApiV3Url(`customers/${customerId}/ship-tos/unlink?childId=${childId}`),
        { withCredentials: true }
    );
    if (response && response.data) {
        if (response.data.errorList.length) showToast("error", response.data.errorList);
        dispatch(getCustomerShipTos(customerId));
        dispatch(setIsRelatedCustomerDialog(false));
        dispatch(getCustomerMissingCertRegion(customerId));
        dispatch(setRelatedCustomer([]));
        if (response.data.billShipTo) {
            setCustomerToStore(dispatch, customer, response.data.billShipTo);
        }
    }
    dispatch(setIsUnlinkingCustomer(false));
};

export const getVendorMissingCertRegion = customerId => async (dispatch, getState) => {
    const { customer } = getState();
    if (customer.isLoadingMissingCertRegion) {
        return false;
    }
    dispatch(setIsLoadingMissingCertRegion(true));
    const response = await axios.get(buildApiV3Url(`customers/${customerId}/missing-exposures`), {
        withCredentials: true,
    });
    if (response && response.data) {
        dispatch(setIsCustomerMissingCertificates(response.data.hasMissingCertificates));
        const exposureZoneArray = response.data.missingExposureZones.sort();
        let missingExposureZones = "";
        for (let i = 0; i < exposureZoneArray.length - 1; i += 1) {
            if (i < exposureZoneArray.length - 2)
                missingExposureZones = missingExposureZones
                    .concat(exposureZoneArray[i])
                    .concat(", ");
            else
                missingExposureZones = missingExposureZones
                    .concat(exposureZoneArray[i])
                    .concat(" and ");
        }
        missingExposureZones = missingExposureZones.concat(
            exposureZoneArray[exposureZoneArray.length - 1]
        );
        dispatch(setCustomerMissingCertRegions(missingExposureZones));
    }
    dispatch(setIsLoadingMissingCertRegion(false));
    return true;
};

export const postCustomersExportAPI =
    (pageSize = 20, pageNumber = 0, selectedColumns, title, showToast, hideToast) =>
    async (dispatch, getState) => {
        const { isLoadingCustomerExport, formState, customerFiltersState, sortColumn } =
            getState().customer;
        const { userId, accountId, role } = getState().session;
        const { nameOrCode, nameOrCodeWhileLoading } = formState;
        if (isLoadingCustomerExport) {
            return;
        }
        dispatch(setIsLoadingCustomerExport(true));
        const searchVal = nameOrCodeWhileLoading !== "" ? nameOrCodeWhileLoading : nameOrCode;
        const response = await axios.post(
            buildApiV3Url(`export-customer?title=${title}`),
            {
                pageSize,
                pageNumber,
                selectedColumns,
                orderBy: sortColumn[0],
                orderByDirection: sortColumn[1] ? "DESC" : "ASC",
                includes: "",
                nameOrCode: fixSearchStringForPostgres(searchVal),
                purchaseOrderNumber: customerFiltersState.invoiceNumber,
                missing: customerFiltersState.isMissingcertificates,
                missingExposureZones: customerFiltersState.missingExposureZones,
                isBillTo: customerFiltersState.isBillToRelation,
                isShipTo: customerFiltersState.isShipToRelation,
                isDuplicate: customerFiltersState.isDuplicateRelation,
                certificateImageExists: customerFiltersState.isCertificateImage,
                isSingle: customerFiltersState.isPurchaseOrder,
                returnEmptyEmailRecords: customerFiltersState.isMissingEmailAddress,
                isVendor: customerFiltersState.isVendorFilter,
                customerCreatedFrom: customerFiltersState.customerCreatedDateAfter
                    ? customerFiltersState.customerCreatedDateAfter
                    : null,
                customerCreatedTo: customerFiltersState.customerCreatedDateBefore
                    ? customerFiltersState.customerCreatedDateBefore
                    : null,
                customerModifiedFrom: customerFiltersState.customerModifiedDateAfter
                    ? customerFiltersState.customerModifiedDateAfter
                    : null,
                customerModifiedTo: customerFiltersState.customerModifiedDateBefore
                    ? customerFiltersState.customerModifiedDateBefore
                    : null,
                certificateCreatedFrom: customerFiltersState.certificateCreatedDateAfter
                    ? customerFiltersState.certificateCreatedDateAfter
                    : null,
                certificateCreatedTo: customerFiltersState.certificateCreatedDateBefore
                    ? customerFiltersState.certificateCreatedDateBefore
                    : null,
                certificateModifiedFrom: customerFiltersState.certificateModifiedDateAfter
                    ? customerFiltersState.certificateModifiedDateAfter
                    : null,
                certificateModifiedTo: customerFiltersState.certificateModifiedDateBefore
                    ? customerFiltersState.certificateModifiedDateBefore
                    : null,
                certificateEffectiveFrom: customerFiltersState.certificateEffectiveDateAfter
                    ? customerFiltersState.certificateEffectiveDateAfter
                    : null,
                certificateEffectiveTo: customerFiltersState.certificateEffectiveDateBefore
                    ? customerFiltersState.certificateEffectiveDateBefore
                    : null,
                certificateExpirationFrom: customerFiltersState.certificateExpirationDateAfter
                    ? customerFiltersState.certificateExpirationDateAfter
                    : null,
                certificateExpirationTo: customerFiltersState.certificateExpirationDateBefore
                    ? customerFiltersState.certificateExpirationDateBefore
                    : null,
                certificateIds: customerFiltersState.certificateIds?.length
                    ? customerFiltersState.certificateIds
                          .split("\n")
                          .map(value => fixSearchStringForPostgres(value.trim()))
                          .map(Number)
                    : [],
                customerCodes: customerFiltersState.customerCodes?.length
                    ? customerFiltersState.customerCodes
                          .split("\n")
                          .map(value => fixSearchStringForPostgres(value.trim()))
                    : [],
                status: customerFiltersState.certificateStatus,
                exposureZones: customerFiltersState.certificateRegions,
                customerRegions: customerFiltersState.customerRegions,
                exemptionReasons: customerFiltersState.exemptReasons,
                avalaraUserId: userId,
                avalaraUserRole: role,
                avaTaxAccountId: accountId,
                alternateId: customerFiltersState.alternateId,
                certificatesLabels: customerFiltersState.certificatesLabels,
                customersLabels: customerFiltersState.customersLabels,
                customerCustomFields: customerFiltersState.customerCustomFields,
            },
            { withCredentials: true }
        );
        dispatch(setIsExportSearchDialogOpen(false));
        dispatch(setIsLoadingCustomerExport(false));
        if (response.status === 200) {
            const baseURL = `https://${docLockerClient}`;
            window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            showToast(
                "success",
                "We are working on your export. When it's ready, find it in",
                "export-success",
                true,
                "your downloads",
                () => {
                    hideToast("export-success");
                    window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
                },
                "The time it takes to export data depends on the size of the file and network traffic."
            );
            return;
        }
        showToast("error", `Something went wrong`);
    };

export const getCustomFields = () => async (dispatch, getState) => {
    const { isLoadingCustomFields } = getState();
    if (isLoadingCustomFields) {
        return false;
    }

    dispatch(setLoadingCustomFields(true));

    const response = await axios.get(buildApiV3Url(`customer-custom-fields?$top=100`), {
        withCredentials: true,
    });

    if (parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
        const getAllResponse = parseGetAllObjResponse(response);
        dispatch(setCustomFields(getAllResponse.value));
    }

    dispatch(setLoadingCustomFields(false));
    return true;
};

export const getCustomerCustomFields = customerID => async (dispatch, getState) => {
    const { isLoadingCustomerCustomFields } = getState();

    if (isLoadingCustomerCustomFields) {
        return false;
    }
    let customFields = null;

    dispatch(setLoadingCustomerCustomFields(true));
    const allFieldsResponse = await axios.get(buildApiV3Url(`customer-custom-fields?$top=100`), {
        withCredentials: true,
    });
    if (parseSuccessResponse(CRUDOperation.ReadAll, allFieldsResponse)?.recordFound) {
        const getAllResponse = parseGetAllObjResponse(allFieldsResponse);
        customFields = getAllResponse.value;
    }

    const response = await axios.get(
        buildApiV3Url(`customers/${customerID}/custom-fields?$top=20`),
        { withCredentials: true }
    );

    const manipulatedCustomFields = {};

    if (response?.data?.value) {
        Object.values(response.data.value).forEach(v => {
            Object.entries(customFields).forEach(([, value], index) => {
                if (v.fieldName === value.fieldName) {
                    customFields[index].value = v.value;
                    customFields[index].id = v.id;
                    customFields[index].exists = true;
                    if (value.type === "multi-select") {
                        let splitted = v?.value?.split("|");
                        splitted = splitted?.filter(x => x !== "");
                        manipulatedCustomFields[v.id] = splitted || null;
                        customFields[index].value = splitted || null;
                    } else {
                        manipulatedCustomFields[v.id] = v?.value;
                    }
                }
            });
        });
    }
    dispatch(setCustomerExistingCustomFieldValues(manipulatedCustomFields));
    dispatch(setCustomerCustomFields(customFields));
    dispatch(setLoadingCustomerCustomFields(false));
    return true;
};

export const getCustomFieldsValidationResults = () => (dispatch, getState) => {
    const { customerAddedCustomFieldValues, customerExistingCustomFieldValues } =
        getState().customer;
    const payloadWithCustomFields = {};
    Object.keys(customerAddedCustomFieldValues).forEach(customFieldId => {
        if (!Array.isArray(customerAddedCustomFieldValues[customFieldId])) {
            payloadWithCustomFields[customFieldId] = {
                value: customerAddedCustomFieldValues[customFieldId],
                validations: [fieldValidationTypeCsv],
            };
        }
    });
    Object.keys(customerExistingCustomFieldValues).forEach(customFieldId => {
        if (!Array.isArray(customerExistingCustomFieldValues[customFieldId])) {
            payloadWithCustomFields[customFieldId] = {
                value: customerExistingCustomFieldValues[customFieldId],
                validations: [fieldValidationTypeCsv],
            };
        }
    });
    return fieldsValidation(payloadWithCustomFields);
};

export const getAllCustomerLabelsFromAPI = () => async dispatch => {
    const allCustomerLabels = await axios.get(
        buildApiV3Url(`customer-labels?$orderBy=name ASC&$top=100`),
        { withCredentials: true }
    );
    if (allCustomerLabels && allCustomerLabels?.data?.value) {
        const transformedCustomerLabels = convertFieldToString(allCustomerLabels.data.value, "id");
        dispatch(setCustomerLabels(transformedCustomerLabels));
    }
};

export const getCustomerLabels = customerID => async (dispatch, getState) => {
    const { isLoadingCustomerLabels } = getState();

    if (isLoadingCustomerLabels) {
        return false;
    }

    dispatch(setLoadingCustomerLabels(true));
    dispatch(getAllCustomerLabelsFromAPI());
    if (customerID) {
        const response = await axios.get(buildApiV3Url(`customers/${customerID}/customer-labels`), {
            withCredentials: true,
        });
        if (response?.data?.value) {
            const transformedCustomerLabel = convertFieldToString(response?.data?.value, "id");
            dispatch(setLabels(transformedCustomerLabel));
        }
    }

    dispatch(setLoadingCustomerLabels(false));
    return true;
};

export const upsertCustomerColumnConfig = preferenceValue => async () => {
    // to handle mismatch with pre-saved ecm preferences.
    const preferenceValueUpdated = JSON.stringify(preferenceValue).replace(
        "customerLabels",
        "customersLabels"
    );
    await axios.post(
        buildApiV3Url(`ecm-preferences`),
        {
            preference: "customer-columns",
            preferenceValue: preferenceValueUpdated,
        },
        { withCredentials: true }
    );
};

export const upsertVendorColumnConfig = preferenceValue => async () => {
    // to handle mismatch with pre-saved ecm preferences.
    const preferenceValueUpdated = JSON.stringify(preferenceValue).replace(
        "vendorLabels",
        "vendorLabels"
    );
    await axios.post(
        buildApiV3Url(`ecm-preferences`),
        {
            preference: "vendor-columns",
            preferenceValue: preferenceValueUpdated,
        },
        { withCredentials: true }
    );
};

export const getCustomerColumnList =
    (isEligibleUserCustomFields, isUserEligibleToViewCustomerLabels) =>
    async (dispatch, getState) => {
        const { defaultCustomerColumns, isLoadingSelectedColumns } = getState().customer;

        if (isLoadingSelectedColumns) {
            return false;
        }
        dispatch(setIsLoadingCustomerColumns(true));
        let config = defaultCustomerColumns.slice();

        // fetch the user preferences and check if there is a saved column config
        const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
            withCredentials: true,
        });

        if (preferenceResponse && preferenceResponse.data && preferenceResponse.data.value) {
            const customerPreference = preferenceResponse.data.value.filter(
                pref => pref.preference === "customer-columns"
            );
            if (customerPreference.length !== 0 && customerPreference[0].preferenceValue) {
                config = JSON.parse(customerPreference[0].preferenceValue);
                // Push newly added columns into config if the user has an already saved preference value
                // without them
                defaultCustomerColumns.forEach(value => {
                    if (!config.some(e => e.id === value.id)) {
                        config.splice(value.index, 0, {
                            id: value.id,
                            label: value.label,
                            hidden: value.hidden,
                        });
                    }
                });
            }
        }
        if (isUserEligibleToViewCustomerLabels) {
            const customerLabelColumnItem = {
                id: "customersLabels",
                label: "Labels",
                hidden: true,
            };
            // Adds the customerLabelColumn after the default columns if not present
            config = [
                ...new Map(
                    [...defaultCustomerColumns, customerLabelColumnItem, ...config].map(item => [
                        item.id,
                        item,
                    ])
                ).values(),
            ];
            // If customersLabels is already present then override its label value with current one
            config = [
                ...config.map(column => {
                    if (column.id === customerLabelColumnItem.id) {
                        return { ...column, label: customerLabelColumnItem.label };
                    }
                    return column;
                }),
            ];
        }
        if (isEligibleUserCustomFields) {
            let customerCustomFields = [];
            // fetch the custom fields
            const response = await axios.get(buildApiV3Url(`customer-custom-fields?$top=100`), {
                withCredentials: true,
            });
            if (parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
                const getAllResponse = parseGetAllObjResponse(response);
                customerCustomFields = getAllResponse.value;
                dispatch(setCustomFields(getAllResponse.value));
            }

            const customFieldIds = new Set(
                customerCustomFields.map(field => removeSpaces(field.fieldName))
            );

            // Remove custom fields not present in exiting preferences
            config = config.filter(
                column => !column.fieldName || customFieldIds.has(removeSpaces(column.fieldName))
            );

            // if there are custom fields, add them to the column list
            if (customerCustomFields.length !== 0) {
                const formatCustomFields = customerCustomFields.map(field => ({
                    id: removeSpaces(field.fieldName),
                    label: field.fieldName,
                    hidden: true,
                    fieldName: field.fieldName,
                }));
                // add custom fields which are not present in column list after the default columns
                config = [
                    ...new Map(
                        [...formatCustomFields.reverse(), ...config.reverse()].map(item => [
                            item.id,
                            item,
                        ])
                    ).values(),
                ].reverse();

                config = [
                    ...config.map(column => {
                        const matchingData = formatCustomFields.find(data => data.id === column.id);
                        if (matchingData) {
                            return {
                                ...column,
                                fieldName: matchingData.fieldName,
                            };
                        }
                        return column;
                    }),
                ];

                dispatch(upsertCustomerColumnConfig(config));
            }
        }
        const modifiedColumns = config.map(column => {
            const matchingData = defaultCustomerColumns.find(data => data.id === column.id);
            if (matchingData) {
                return {
                    ...column,
                    label: matchingData.label,
                };
            }
            return column;
        });
        dispatch(setDefaultCustomerColumns(modifiedColumns));
        dispatch(setIsLoadingCustomerColumns(false));
        return true;
    };

export const getVendorColumnList =
    (isEligibleUserVendorCustomFields, isUserEligibleToViewVendorLabels) =>
    async (dispatch, getState) => {
        const { defaultVendorColumns, isLoadingSelectedColumns } = getState().customer;

        if (isLoadingSelectedColumns) {
            return false;
        }
        dispatch(setIsLoadingCustomerColumns(true));
        let vConfig = defaultVendorColumns;

        // fetch the user preferences and check if there is a saved column config
        const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
            withCredentials: true,
        });

        if (preferenceResponse && preferenceResponse.data && preferenceResponse.data.value) {
            const customerPreference = preferenceResponse.data.value.filter(
                pref => pref.preference === "vendor-columns"
            );
            if (customerPreference.length !== 0 && customerPreference[0].preferenceValue) {
                vConfig = JSON.parse(customerPreference[0].preferenceValue);
                // Push newly added columns into config if the user has an already saved preference value
                // without them
                defaultVendorColumns.forEach(value => {
                    if (!vConfig.some(e => e.id === value.id)) {
                        vConfig.splice(value.index, 0, {
                            id: value.id,
                            label: value.label,
                            hidden: value.hidden,
                        });
                    }
                });
            }
        }
        if (isUserEligibleToViewVendorLabels) {
            const vendorLabelColumnItem = {
                id: "vendorLabels",
                label: "Labels",
                hidden: true,
            };
            // Adds the vendorLabelColumn after the default columns if not present
            vConfig = [
                ...new Map(
                    [...defaultVendorColumns, vendorLabelColumnItem, ...vConfig].map(item => [
                        item.id,
                        item,
                    ])
                ).values(),
            ];
            // If vendorsLabels is already present then override its label value with current one
            vConfig = [
                ...vConfig.map(column => {
                    if (column.id === vendorLabelColumnItem.id) {
                        return { ...column, label: vendorLabelColumnItem.label };
                    }
                    return column;
                }),
            ];
        }
        if (isEligibleUserVendorCustomFields) {
            let vendorCustomFields = [];
            // fetch the custom fields
            const response = await axios.get(buildApiV3Url(`customer-custom-fields?$top=100`), {
                withCredentials: true,
            });
            if (parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
                const getAllResponse = parseGetAllObjResponse(response);
                vendorCustomFields = getAllResponse.value;
                dispatch(setCustomFields(getAllResponse.value));
            }
            // if there are custom fields, add them to the column list
            if (vendorCustomFields.length !== 0) {
                const formatCustomFields = vendorCustomFields.map(field => ({
                    id: removeSpaces(field.fieldName),
                    label: field.fieldName,
                    hidden: true,
                    fieldName: field.fieldName,
                }));
                // add custom fields which are not present in column list after the default columns
                vConfig = [
                    ...new Map(
                        [...formatCustomFields.reverse(), ...vConfig.reverse()].map(item => [
                            item.id,
                            item,
                        ])
                    ).values(),
                ].reverse();

                vConfig = [
                    ...vConfig.map(column => {
                        const matchingData = formatCustomFields.find(data => data.id === column.id);
                        if (matchingData) {
                            return {
                                ...column,
                                fieldName: matchingData.fieldName,
                            };
                        }
                        return column;
                    }),
                ];

                dispatch(upsertVendorColumnConfig(vConfig));
            }
        }
        const modifiedVendorColumns = vConfig.map(column => {
            const matchingData = defaultVendorColumns.find(data => data.id === column.id);
            if (matchingData) {
                return {
                    ...column,
                    label: matchingData.label,
                };
            }
            return column;
        });
        dispatch(setDefaultVendorColumns(modifiedVendorColumns));
        dispatch(setIsLoadingCustomerColumns(false));
        return true;
    };

export const deleteBulkCustomers =
    (showToast, isCertificateDelete) => async (dispatch, getState) => {
        const { isDeletingCustomer, customerIds } = getState().customer;

        if (isDeletingCustomer) {
            return false;
        }

        dispatch(setDeletingCustomer(true));
        const body = { customerIds, isCertificateDelete };
        const response = await axios.delete(buildApiV3Url(`bulk-customers`), {
            data: body,
            withCredentials: true,
        });
        dispatch(setDeletingCustomer(false));
        if (response?.data?.success) {
            const message =
                response?.data?.returnValue?.deletedCustomerIds?.length > 1
                    ? "customers"
                    : "customer ";
            showToast(
                "success",
                `Deleted ${response?.data?.returnValue?.deletedCustomerIds?.length} ${message}`
            );
        }

        dispatch(fetchCustomersAPI());
        return true;
    };

export default customerSlice.reducer;
