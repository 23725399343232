import { fixSearchStringForPostgres } from "../../shared/Utils";
import { CertificateStatuses, completeRequestStatuses } from "../../shared/constants";

// Helper functions
const getSearchValue = formState => {
    const { nameOrCode, nameOrCodeWhileLoading } = formState;
    return nameOrCodeWhileLoading || nameOrCode;
};

const getCertificateStatuses = certificateStatuses => {
    return localStorage.getItem("certificateStatuses")
        ? JSON.parse(localStorage.getItem("certificateStatuses"))
        : certificateStatuses;
};

const getExemptionStatuses = (certificateFiltersState, statuses) => {
    const exemptionStatuses = [];

    const hasValidStatus = completeRequestStatuses.some(status =>
        certificateFiltersState?.certificateStatus?.includes(status)
    );

    if (hasValidStatus) {
        const completeStatus = statuses.find(
            status => status.name === CertificateStatuses.Complete
        )?.id;
        const pendingFutureStatus = statuses.find(
            status => status.name === CertificateStatuses.PENDING_FUTURE
        )?.id;

        if (completeStatus) exemptionStatuses.push(completeStatus);
        if (pendingFutureStatus) exemptionStatuses.push(pendingFutureStatus);
    } else if (
        certificateFiltersState?.certificateStatus?.includes(CertificateStatuses.PendingFuture)
    ) {
        const pendingFutureStatus = statuses.find(
            status => status.name === CertificateStatuses.PENDING_FUTURE
        )?.id;
        if (pendingFutureStatus) exemptionStatuses.push(pendingFutureStatus);
    }

    return exemptionStatuses;
};

const getDateRangeValue = (dateAfter, dateBefore) => ({
    from: dateAfter || null,
    to: dateBefore || null,
});

const buildDateRanges = certificateFiltersState => {
    return {
        customerCreated: getDateRangeValue(
            certificateFiltersState.customerCreatedDateAfter,
            certificateFiltersState.customerCreatedDateBefore
        ),
        customerModified: getDateRangeValue(
            certificateFiltersState.customerModifiedDateAfter,
            certificateFiltersState.customerModifiedDateBefore
        ),
        certificateCreated: getDateRangeValue(
            certificateFiltersState.certificateCreatedDateAfter,
            certificateFiltersState.certificateCreatedDateBefore
        ),
        certificateModified: getDateRangeValue(
            certificateFiltersState.certificateModifiedDateAfter,
            certificateFiltersState.certificateModifiedDateBefore
        ),
        certificateEffective: getDateRangeValue(
            certificateFiltersState.certificateEffectiveDateAfter,
            certificateFiltersState.certificateEffectiveDateBefore
        ),
        certificateExpiration: getDateRangeValue(
            certificateFiltersState.certificateExpirationDateAfter,
            certificateFiltersState.certificateExpirationDateBefore
        ),
    };
};

const processIdentifiers = (ids, processor = v => v) => {
    return ids?.length ? ids.split("\n").map(value => processor(value.trim())) : [];
};

function certificateFiltersData(selectedColumns, getState, pageSize = 20, pageNumber = 0) {
    const { userId, accountId, role } = getState().session;
    const { formState, certificateFiltersState, sortColumn, certificateStatuses } =
        getState().certificate;

    const searchVal = getSearchValue(formState);
    const statuses = getCertificateStatuses(certificateStatuses);
    const exemptionStatuses = getExemptionStatuses(certificateFiltersState, statuses);
    const dateRanges = buildDateRanges(certificateFiltersState);

    return {
        // Pagination and sorting
        pageSize,
        pageNumber,
        selectedColumns,
        orderBy: sortColumn[0],
        orderByDirection: sortColumn[1] ? "ASC" : "DESC",

        // Search and filters
        nameOrCode: fixSearchStringForPostgres(searchVal),
        purchaseOrderNumber: certificateFiltersState.invoiceNumber,

        // Boolean flags
        isPrimary: certificateFiltersState.isCertificateActive,
        isBillTo: certificateFiltersState.isBillToRelation,
        isShipTo: certificateFiltersState.isShipToRelation,
        isDuplicate: certificateFiltersState.isDuplicateRelation,
        isSingle: certificateFiltersState.isPurchaseOrder,
        isVendor: certificateFiltersState.isVendorFilter,
        certificateImageExists: certificateFiltersState.isCertificateImage,

        // Date ranges
        customerCreatedFrom: dateRanges.customerCreated.from,
        customerCreatedTo: dateRanges.customerCreated.to,
        customerModifiedFrom: dateRanges.customerModified.from,
        customerModifiedTo: dateRanges.customerModified.to,
        certificateCreatedFrom: dateRanges.certificateCreated.from,
        certificateCreatedTo: dateRanges.certificateCreated.to,
        certificateModifiedFrom: dateRanges.certificateModified.from,
        certificateModifiedTo: dateRanges.certificateModified.to,
        certificateEffectiveFrom: dateRanges.certificateEffective.from,
        certificateEffectiveTo: dateRanges.certificateEffective.to,
        certificateExpirationFrom: dateRanges.certificateExpiration.from,
        certificateExpirationTo: dateRanges.certificateExpiration.to,

        // Status and categories
        status: certificateFiltersState.certificateStatus,
        exemptionStatus: exemptionStatuses,
        exposureZones: certificateFiltersState.certificateRegions,
        exemptionReasons: certificateFiltersState.exemptReasons,
        customerRegions: certificateFiltersState.customerRegions,

        // Additional properties
        certificateHasCustomers: certificateFiltersState.certificateHasCustomers,
        activeCertificate: certificateFiltersState.isCertActive,
        certificateIds: processIdentifiers(certificateFiltersState.certificateIds, Number),
        customerCodes: processIdentifiers(
            certificateFiltersState.customerCodes,
            fixSearchStringForPostgres
        ),

        // User info
        avalaraUserId: userId,
        avalaraUserRole: role,
        avaTaxAccountId: accountId,

        // Custom fields and labels
        certificateCustomFields: certificateFiltersState.certificateCustomFields,
        certificatesLabels: certificateFiltersState.certificatesLabels,
        customersLabels: certificateFiltersState.customersLabels,
        reviewedBy: certificateFiltersState.reviewedBy,
    };
}

export default certificateFiltersData;
