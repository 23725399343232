import { SCol, SDialog, SIcon, SRow } from "@avalara/skylab-react";
import classnames from "classnames";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    postSavedSearchAPI,
    selectIsPostloading,
    selectIsSavedSearchDialog,
    setIsSavedSearchDialog,
    setSavedSearchesState,
} from "../../app/savedSearchesSlice";

import { selectSession } from "../../app/sessionSlice";
import { noErrorTag } from "../../shared/Utils";

function SaveSearchDialog(props) {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const loading = useSelector(selectIsPostloading);
    const isSaveSearchDialogOpen = useSelector(selectIsSavedSearchDialog);
    const session = useSelector(selectSession, shallowEqual);
    const saveClassName = classnames({ primary: true, small: true, loading });

    const handleSubmitForm = event => {
        event.preventDefault();
        if (title) {
            const searchObj = JSON.parse(JSON.stringify(props.criteria));
            searchObj.savedSearch = [title];

            dispatch(
                setSavedSearchesState({
                    aiUserId: session.userId,
                    cannedSearch: "User",
                    searchType: props.searchType,
                    title,
                    criteria: searchObj,
                })
            );
            dispatch(postSavedSearchAPI());
            setTitle("");
        } else {
            setTitleError("error");
        }
    };

    const onSDismiss = () => {
        setTitle("");
        dispatch(setIsSavedSearchDialog(false));
    };
    const handleChangeInput = event => {
        setTitle(event.target.value);
        setTitleError("");
    };

    return isSaveSearchDialogOpen ? (
        <SDialog
            open={isSaveSearchDialogOpen}
            id="SaveSearch-dialog"
            onS-dismiss={onSDismiss}
            aria-modal="true">
            <div slot="header" id="saveSearch">
                Save this search
            </div>
            <div slot="body">
                <SRow>
                    <SCol>
                        <label htmlFor="searchTitle" className="required" id="lbl-searchTitle">
                            Give your search a name
                        </label>
                        <input
                            name="searchTitle"
                            id="searchTitle"
                            type="text"
                            value={title}
                            className={titleError || noErrorTag}
                            onChange={e => {
                                handleChangeInput(e);
                            }}
                        />
                        {titleError ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a name for search</span>
                            </div>
                        ) : null}
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="secondary small" onClick={onSDismiss}>
                    Cancel
                </button>
                <button className={saveClassName} onClick={handleSubmitForm}>
                    Save
                </button>
            </div>
        </SDialog>
    ) : null;
}

export default SaveSearchDialog;
