import { createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import { parseSuccessResponse, parseGetAllObjResponse } from "../shared/responseUtils";
import toast from "../hooks/toast";
import { CRUDOperation } from "../shared/constants";

export const productCategoriesSlice = createSlice({
    name: "productCategories",
    initialState: {
        isLoading: false,
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        categories: [],
        sortColumn: ["modified", true],
        noDataType: "",
        postError: "",
        avaProductCategories: [],
        isLoadingAvaProductCategories: false,
        clientProductCategories: [],
        certificateProductCategories: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setClientProductCategories: (state, action) => {
            state.clientProductCategories = action.payload;
        },
        setCertificateProductCategories: (state, action) => {
            state.certificateProductCategories = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setAvaProductCategories: (state, action) => {
            state.avaProductCategories = action.payload;
        },
        setIsLoadingAvaProductCategories: (state, action) => {
            state.isLoadingAvaProductCategories = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setNoDataType,
    setAvaProductCategories,
    setIsLoadingAvaProductCategories,
    setClientProductCategories,
    setCertificateProductCategories,
} = productCategoriesSlice.actions;

export const selectPage = state => {
    return state.productCategories.page;
};

export const selectSortColumn = state => {
    return state.productCategories.sortColumn;
};

export const selectIsLoading = state => {
    return state.productCategories.isloading;
};

export const selectIsLoadingAvaProductCategories = state => {
    return state.productCategories.isLoadingAvaCat;
};
export const selectNoData = state => {
    return state.productCategories.noDataType;
};

export const selectAvaProductCategories = state => {
    return state.productCategories.avaProductCategories;
};

export const selectClientProductCategories = state => {
    return state.productCategories.clientProductCategories;
};

export const selectCertificateProductCategories = state => {
    return state.productCategories.certificateProductCategories;
};

const [showToast] = toast();

export const fetchAvaTaxCategoriesAsync = () => async (dispatch, getState) => {
    const { isLoadingAvaProductCategories } = getState().exemptionMatrix;

    if (isLoadingAvaProductCategories) {
        return;
    }

    dispatch(setIsLoadingAvaProductCategories(true));

    const response = await axios.get(buildApiV3Url(`product-exemptions/categories`), {
        withCredentials: true,
    });

    if (response.data) {
        dispatch(setAvaProductCategories(response.data));
    }

    dispatch(setIsLoadingAvaProductCategories(false));
};

export const fetchClientProductCategories = () => async (dispatch, getState) => {
    const { isLoading } = getState().productCategories;

    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const apiURI = buildApiV3UrlWithQuery(`exempt-product-categories`, "", "", 500, "");

    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    dispatch(setClientProductCategories(response.data.value));
};

export const fetchCertificateProductCategories = certId => async (dispatch, getState) => {
    const { isLoading } = getState().productCategories;

    if (isLoading) {
        return;
    }
    dispatch(setLoading(true));

    const apiURI = buildApiV3Url(`certificates/${certId}/exempt-product-categories`);

    const response = await axios.get(`${apiURI}`, { withCredentials: true });
    dispatch(setCertificateProductCategories(response.data));
};
export const fetchCategoriesAsync =
    (top, skip = 0, onLanding, paginateDetails) =>
    async (dispatch, getState) => {
        const { isLoading } = getState().productCategories;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;

        if (isLoading) {
            return;
        }
        dispatch(setLoading(true));

        const apiURI = buildApiV3UrlWithQuery(`exempt-product-categories`, "", "", top, skip);

        const response = await axios.get(`${apiURI}`, { withCredentials: true });
        if (!parseSuccessResponse(CRUDOperation.ReadAll, response)?.recordFound) {
            dispatch(setNoDataType(true));
            dispatch(setPageToNull());
        } else {
            dispatch(setNoDataType(""));
            const readAllResponse = parseGetAllObjResponse(response);
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: readAllResponse?.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 10,
                      totalRecords: readAllResponse?.count,
                  };
            dispatch(
                setPage({
                    data: readAllResponse?.value,
                    paginate: paginateData,
                })
            );
        }

        dispatch(setLoading(false));
    };

export const productCategoryCertificateCountAsync = categoryName => async () => {
    const response = await axios.get(buildApiV3Url(`product-exemption-certificates-count`), {
        params: {
            category: categoryName,
        },
        withCredentials: true,
    });

    return response.data;
};

export const deleteProductCategoryAsync = categoryName => async dispatch => {
    const response = await axios.delete(buildApiV3Url(`exempt-product-categories`), {
        data: {
            category: categoryName,
        },
        withCredentials: true,
    });
    const deleteResponse = parseSuccessResponse(CRUDOperation.Delete, response);
    if (deleteResponse?.isSuccess && deleteResponse?.isJobQueued) {
        showToast(
            "success",
            `Your product category delete request has been queued for processing.`,
            "deleteProductCategoryToast",
            false
        );
    } else if (deleteResponse?.isSuccess && !deleteResponse?.isJobQueued) {
        showToast("success", `Category deleted successfully.`, "deleteProductCategoryToast", false);
    } else {
        showToast(
            "error",
            `It seems your category wasn't deleted. Try deleting it again.`,
            "deleteProductCategoryToast",
            false
        );
    }
    dispatch(fetchCategoriesAsync());
    return response;
};

export const updateProductCategoryAsync = putData => async dispatch => {
    dispatch(setLoading(true));
    const response = await axios.put(buildApiV3Url(`exempt-product-categories`), putData, {
        withCredentials: true,
    });
    const updateResponse = parseSuccessResponse(CRUDOperation.Update, response);
    if (updateResponse?.isSuccess && updateResponse?.isJobQueued) {
        showToast(
            "success",
            `Your product category update request has been queued for processing.`,
            "updateProductCategoryToast",
            false
        );
    } else if (updateResponse?.isSuccess && !updateResponse?.isJobQueued) {
        showToast("success", `Category updated.`, "updateProductCategoryToast", false);
    }
    dispatch(fetchCategoriesAsync());
    dispatch(setLoading(false));
    return response;
};
export default productCategoriesSlice.reducer;
