import { useCallback, useRef } from "react";
import isEqual from "lodash/isEqual";

function useDeepCompareCallback(callback, dependencies) {
    const currentDependencies = useRef(dependencies);

    if (!isEqual(currentDependencies.current, dependencies)) {
        currentDependencies.current = dependencies;
    }

    return useCallback(callback, [currentDependencies.current]);
}

export default useDeepCompareCallback;
