import React, { useState } from "react";
import { SDatepicker, SIcon } from "@avalara/skylab-react";

function Datepicker(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const handleDateSelect = e => {
        if (props.onSelect) {
            props.onSelect(e);
            if (!e.detail.message) {
                e.target.classList.remove("error");
            }
        }
    };

    const handleDateDeselect = e => {
        if (props.onDeselect) {
            props.onDeselect(e);
        }
    };

    const handleDateError = e => {
        if (props.onError) {
            props.onError(e);
            e.target.classList.add("error");
            setErrorMessage(e.detail.message);
        }
    };

    return (
        <>
            <label htmlFor={props.id}>{props.dateFor}</label>
            <SDatepicker
                inputId={props.id}
                className="width-auto"
                disabled={props.isLoading}
                onS-select={handleDateSelect}
                value={props.value}
                onS-deselect={handleDateDeselect}
                onS-error={handleDateError}
            />
            {errorMessage && (
                <div className="input-msg">
                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                    {errorMessage}
                </div>
            )}
        </>
    );
}

export default Datepicker;
