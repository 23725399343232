import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SAlert, SSelect, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { debounce } from "lodash";
import axios from "../../axios";
import { fixSearchStringForPostgres, buildApiV3Url } from "../../shared/Utils";
import { getCountriesWithStateCountAsync, selectCountriesStateCount } from "../../app/commonSlice";
import EditCustomeDialog from "../sharedDialogs/EditCustomeDialog";
import AddCustomerDialog from "../sharedDialogs/AddCustomerDialog";
import FeatureToggler from "../../featureToggler/FeatureToggler";
import CustomerInfoPanel from "./CustomerInfoPanel";
import { isEligibleUser } from "../../shared/sessionUtility";
import featureFlag from "../../featureToggler/featureFlag";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import useDeepCompareCallback from "../../hooks/useDeepCompareCallback";
import { isCustomerAddressRequired } from "../../shared/fieldRequirementCheckUtil";

const CustomerTypeAhead = React.memo(props => {
    const dispatch = useDispatch();
    const venRef = useRef(null);
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [multiCustomerDialogOpen, setMultiCustomerDialogOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isCustomerDataMissing, setIsCustomerDataMissing] = useState(false);
    const countries = useSelector(selectCountriesStateCount, shallowEqual);
    const [customersMissingData, setCustomersMissingData] = useState([]);
    const [focusedCustomer, setFocusedCustomer] = useState(null);
    const [optionsList, setOptionsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customerAddressRequired, setCustomerAddressRequired] = useState(true);
    const isEligibleUserCustomersManageCustomer = dispatch(
        isEligibleUser(featureFlag.customers.manageCustomer)
    );
    const { setCustomerDataValidation, handleAdd, handleRemove } = props;

    useEffect(() => {
        setCustomerAddressRequired(dispatch(isCustomerAddressRequired()));
    }, [dispatch]);

    useEffect(() => {
        if (setCustomerDataValidation) setCustomerDataValidation(isCustomerDataMissing);
    }, [isCustomerDataMissing, setCustomerDataValidation]);

    useEffect(() => {
        if (props.queueDelete) venRef.current.deselectAll();
    }, [props.queueDelete]);

    const fetchTypeAhead = useCallback(
        async value => {
            const updatedValue = fixSearchStringForPostgres(value);
            let typeAheadURL = buildApiV3Url(
                `customers/typeahead?$filter=name contains '${updatedValue}' or customerNumber contains '${updatedValue}' or addressLine1 contains '${updatedValue}' and isVendor eq false  &$orderBy=name ASC&$top=10`
            );

            if (props.vendor) {
                typeAheadURL = buildApiV3Url(
                    `customers/typeahead?$filter=customerNumber contains '${updatedValue}' or name contains '${updatedValue}' or addressLine1 contains '${updatedValue}' and isVendor eq true &$orderBy=name ASC&$top=10`
                );
            }
            const response = await axios.get(typeAheadURL, {
                withCredentials: true,
            });
            return response.data;
        },
        [props.vendor]
    );

    const customerLink = useDeepCompareCallback(
        event => {
            if (event?.target?.parentElement?.id) {
                const idRegex = event.target.parentElement.id.match(/-(.*)-/);
                if (idRegex && idRegex.length >= 1) {
                    const customerId = parseInt(idRegex[1], 10);
                    const idIndex = customers.findIndex(element => element.id === customerId);
                    if (idIndex > -1) {
                        setFocusedCustomer(customers[idIndex]);
                    }
                }
            }
        },
        [customers]
    );

    const closeSelect = useCallback(() => {
        setFocusedCustomer(null);
    }, [setFocusedCustomer]);

    const isAddressZipInvalid = useCallback(customer => {
        if (customer.addressLine1.length > 50) return true;
        const zip = customer.zip ? customer.zip : customer.zipCode;
        if (customer.country?.name === "United States" && (zip.length < 5 || zip.length > 10))
            return true;
        if (customer.country?.name === "Canada" && (zip.length < 6 || zip.length > 10)) return true;
        return false;
    }, []);

    const validationCustomers = useDeepCompareCallback(() => {
        setIsCustomerDataMissing(false);
        if (props?.value?.length > 0) {
            dispatch(getCountriesWithStateCountAsync());
            const customerValidate = props?.value
                .map(customer => {
                    if (
                        !customer.customerNumber ||
                        (!customer.name && !customer.customerName) ||
                        (customerAddressRequired &&
                            (!customer.country ||
                                !customer.addressLine1 ||
                                !customer.city ||
                                (!customer.zip && !customer.zipCode) ||
                                isAddressZipInvalid(customer) ||
                                (countries?.length > 0 &&
                                    customer.country &&
                                    countries.filter(
                                        x => x.name === customer.country.name && x.stateCount > 0
                                    ).length > 0 &&
                                    !customer.state)))
                    ) {
                        return customer;
                    }
                    return null;
                })
                .filter(c => c);
            if (customerValidate?.length > 0) {
                setIsCustomerDataMissing(true);
                setCustomersMissingData(customerValidate);
            }
        }
    }, [countries, dispatch, props?.value, isAddressZipInvalid]);

    useDeepCompareEffect(() => {
        setIsCustomerDataMissing(false);
        if (props?.value?.length > 0 && !props.fromSingleRequests) {
            validationCustomers();
        }
    }, [props?.value, props.fromSingleRequests, validationCustomers]);

    useDeepCompareEffect(() => {
        setFocusedCustomer(null);
        if (props.value && (!customers.length || props.value.length > customers.length)) {
            setCustomers(props.value);
            setOptionsList(
                props.value.map(element => {
                    return {
                        label: `${element.name ? element.name : element.customerName} : ${
                            element.customerNumber
                        }`,
                        value: `${element.id}`,
                        selected: true,
                    };
                })
            );
        }
    }, [props.value, customers.length]);

    const uniqueCustomer = useCallback((prevCustomer, newCustomer) => {
        if (!prevCustomer.length) return newCustomer;
        if (!newCustomer.length) return prevCustomer;

        const prevCustomerIds = prevCustomer.map(ele => ele.id);
        return [
            ...prevCustomer,
            ...newCustomer.filter(cust => prevCustomerIds.indexOf(cust.id) === -1),
        ];
    }, []);

    const typeAhead = debounce(async e => {
        let typeAheadResults = {};
        if (e.detail.inputValue !== "" && e.detail.inputValue.length > 1) {
            setLoading(true);
            const results = await fetchTypeAhead(e.detail.inputValue);
            if (results) {
                typeAheadResults = results.map(element => {
                    return {
                        label: `${element.name ? element.name : element.customerName} : ${
                            element.customerNumber
                        } : ${element.addressLine1}`,
                        value: `${element.id}`,
                    };
                });
                setOptionsList(typeAheadResults);
                setCustomers(uniqueCustomer(customers, results));
            }
            setLoading(false);
        }
    }, 200);

    const onEditUpdateCustomers = useCallback(
        updatedCustomers => {
            const removeCustomer = updatedCustomers.map(c => ({
                value: c.id,
            }));
            removeCustomer.forEach(customer => {
                handleRemove(customer);
            });
            handleAdd(updatedCustomers);
        },
        [handleAdd, handleRemove]
    );
    const disabledAttr = props?.disabled || props?.venDisable ? { disabled: true } : {};
    return (
        <React.Fragment>
            <SRow>
                <SCol span="8" className="pad-bottom-none">
                    <label id="lbl-async-select" htmlFor="async-select-input">
                        {props.label}
                    </label>
                </SCol>
                <FeatureToggler category="customers" id="manageCustomer">
                    <SCol span="4" className="pad-bottom-none">
                        {props.addNewCustomer && (
                            <span className="right">
                                <button
                                    className="link text-label"
                                    disabled={
                                        props?.disabled ||
                                        !!props.vendor ||
                                        !isEligibleUserCustomersManageCustomer
                                    } // eslint-disable-line
                                    onClick={() => {
                                        setCustomerDialogOpen(true);
                                    }}>
                                    {!props.vendor ? "Create a customer" : "Create a vendor"}
                                </button>
                            </span>
                        )}
                    </SCol>
                </FeatureToggler>
            </SRow>
            <SRow>
                <SCol className="pad-bottom-none">
                    <SSelect
                        id="async-select"
                        inputId="async-select-input"
                        loading={loading}
                        ref={venRef}
                        className={props.error ? props.error : "text-as-link"}
                        async
                        multiple={!props.fromSingleRequests}
                        placeholder={!props.vendor ? "Search customers..." : "Search vendors..."}
                        optionsList={optionsList}
                        onS-select={e => {
                            props.handleAdd(
                                customers.filter(cust => cust.id == e.detail.item.value)
                            ); /* eslint eqeqeq: 0 */
                        }}
                        onS-deselect={e => {
                            props.handleRemove(e.detail.item);
                        }}
                        onS-input={typeAhead}
                        onS-open={customerLink}
                        onS-close={closeSelect}
                        onClick={e => {
                            customerLink(e);
                        }}
                        {...disabledAttr}
                    />
                    <div className="input-msg" hidden={!props?.customerError}>
                        <SIcon name="alert-circle-filled" aria-hidden="true" />
                        <span className="top-xs">Enter a customer</span>
                    </div>
                    {focusedCustomer && (
                        <CustomerInfoPanel
                            focusedCustomer={focusedCustomer}
                            setFocusedCustomer={setFocusedCustomer}
                        />
                    )}
                </SCol>
            </SRow>
            <SRow>
                {isCustomerDataMissing && (
                    <SCol>
                        <SAlert status="error" role="alert" nodismiss>
                            <SRow>
                                <SCol>
                                    One or more customer record is missing some information needed
                                    in order for their transaction to be exempt.
                                    <button
                                        className="no-border pad-all-xs fix-it-link"
                                        disabled={!isEligibleUserCustomersManageCustomer}
                                        onClick={() => {
                                            setMultiCustomerDialogOpen(true);
                                        }}>
                                        Fix it now
                                    </button>
                                </SCol>
                            </SRow>
                        </SAlert>
                    </SCol>
                )}
                {multiCustomerDialogOpen ? (
                    <EditCustomeDialog
                        customerDialogOpen={multiCustomerDialogOpen}
                        setCustomerDialogOpen={setMultiCustomerDialogOpen}
                        customers={customersMissingData}
                        onEditUpdateCustomers={onEditUpdateCustomers}
                        setIsCustomerDataMissing={setIsCustomerDataMissing}
                    />
                ) : null}
            </SRow>
            {customerDialogOpen ? (
                <AddCustomerDialog
                    customerDialogOpen={customerDialogOpen}
                    setCustomerDialogOpen={setCustomerDialogOpen}
                    handleAddCustomer={props?.handleAdd}
                />
            ) : null}
        </React.Fragment>
    );
});

CustomerTypeAhead.displayName = "CustomerTypeAhead";
export default CustomerTypeAhead;
