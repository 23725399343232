import axios from "../../axios";
import { buildApiV3Url, removeSpaces } from "../../shared/Utils";
import { documentTypeNames } from "../../shared/constants";
import { setCustomFields, setIsLoadingSelectedColumns, setSelectedColumns } from "./index";

const getCertificateColumnList =
    (
        isEligibleUserCustomFields,
        isUserEligibleToViewCertificateLabels,
        isUserEligibleToViewReviewedBy
    ) =>
    async (dispatch, getState) => {
        if (!shouldProcessRequest(getState().certificate.isLoadingSelectedColumns)) {
            return false;
        }

        dispatch(setIsLoadingSelectedColumns(true));
        try {
            let config = await getInitialConfig(getState().certificate.selectedColumns);

            config = await addPermissionBasedColumns(
                config,
                {
                    isUserEligibleToViewCertificateLabels,
                    isUserEligibleToViewReviewedBy,
                },
                getState().certificate.selectedColumns.length
            );

            if (isEligibleUserCustomFields) {
                config = await processCustomFields(config, dispatch);
            }

            dispatch(setSelectedColumns(config));
            return true;
        } finally {
            dispatch(setIsLoadingSelectedColumns(false));
        }
    };

const shouldProcessRequest = isLoadingSelectedColumns => {
    return !isLoadingSelectedColumns;
};

const getInitialConfig = async selectedColumns => {
    const config = [...selectedColumns];
    const preferenceResponse = await axios.get(buildApiV3Url(`ecm-preferences`), {
        withCredentials: true,
    });

    return extractPreferenceConfig(preferenceResponse, config);
};

const extractPreferenceConfig = (preferenceResponse, defaultConfig) => {
    if (!preferenceResponse?.data?.value) {
        return defaultConfig;
    }

    const certificatePreference = preferenceResponse.data.value.find(
        pref => pref.preference === "certificate-columns"
    );

    return certificatePreference?.preferenceValue
        ? JSON.parse(certificatePreference.preferenceValue)
        : defaultConfig;
};

const addPermissionBasedColumns = async (config, permissions, defaultColumnsLength) => {
    if (permissions.isUserEligibleToViewCertificateLabels) {
        config = addLabelColumn(config, defaultColumnsLength);
    }

    if (permissions.isUserEligibleToViewReviewedBy) {
        config = addReviewedByColumn(config, defaultColumnsLength);
    }

    return config;
};

const addLabelColumn = (config, defaultColumnsLength) => {
    const labelColumn = {
        id: "certificatesLabels",
        label: "Labels",
        hidden: true,
    };

    return updateOrAddColumn(config, labelColumn, defaultColumnsLength);
};

const addReviewedByColumn = (config, defaultColumnsLength) => {
    const reviewedByColumn = {
        id: "uploadedBy",
        label: "Reviewed by",
        hidden: true,
    };

    const columnIndex = config.findIndex(x => x.id === reviewedByColumn.id);
    if (columnIndex === -1) {
        config.splice(defaultColumnsLength + 1, 0, reviewedByColumn);
    }

    return config;
};

const updateOrAddColumn = (config, columnItem, defaultColumnsLength) => {
    const columnIndex = config.findIndex(x => x.id === columnItem.id);

    if (columnIndex !== -1) {
        config[columnIndex] = {
            ...config[columnIndex],
            label: columnItem.label,
        };
    } else {
        config.splice(defaultColumnsLength, 0, columnItem);
    }

    return config;
};

const processCustomFields = async (config, dispatch) => {
    const certificateCustomFields = await fetchCustomFields();

    if (!certificateCustomFields.length) {
        return config;
    }

    dispatch(setCustomFields(certificateCustomFields));

    return updateConfigWithCustomFields(config, certificateCustomFields);
};

const fetchCustomFields = async () => {
    const response = await axios.get(
        buildApiV3Url(
            `certificate-custom-fields?$filter=documentType.Name eq '${documentTypeNames.salesAndUseTax}' and documentType.Outgoing eq false&$top=100`
        ),
        {
            withCredentials: true,
        }
    );

    return response?.data?.value || [];
};

const updateConfigWithCustomFields = (config, certificateCustomFields) => {
    config = filterExistingCustomFields(config, certificateCustomFields);

    const formatCustomFields = certificateCustomFields.map(field => ({
        id: removeSpaces(field.fieldName),
        label: field.fieldName,
        hidden: true,
        fieldName: field.fieldName,
    }));

    return mergeCustomFields(config, formatCustomFields);
};

const filterExistingCustomFields = (config, certificateCustomFields) => {
    return config.filter(
        column =>
            !column.fieldName ||
            (column.fieldName &&
                certificateCustomFields.some(col => col.fieldName === column.fieldName))
    );
};

const mergeCustomFields = (config, formatCustomFields) => {
    // Add missing custom fields
    formatCustomFields.forEach(customField => {
        if (!config.find(column => column.id === customField.id)) {
            config.push(customField);
        }
    });

    // Update existing fields with current data
    return config.map(column => {
        const matchingData = formatCustomFields.find(data => data.id === column.id);
        return matchingData ? { ...column, fieldName: matchingData.fieldName } : column;
    });
};

export default getCertificateColumnList;
