import { createSlice } from "@reduxjs/toolkit";
import { previousDays, buildApiV3UrlWithQuery, buildApiV3Url } from "../shared/Utils";
import { parseGetAllObjResponse, parseSuccessResponse } from "../shared/responseUtils";
import { CRUDOperation, UnkownError } from "../shared/constants";
import toast from "../hooks/toast";
import axios from "../axios";
import getConfig from "../config";

const { hostnames } = getConfig();
const { docLockerClient } = hostnames;

const initialState = {
    isLoading: false,
    page: {
        data: [],
        paginate: {
            pageNumber: 0,
            startIndex: -1,
            endIndex: 0,
            rowsPerPage: 0,
            totalRecords: 0,
        },
    },
    formState: {
        toDate: new Date().toISOString().slice(0, 10),
        fromDate: previousDays(30),
        logType: "",
    },
    sortColumn: ["created", false],
    isExportGlobalAdminLogsLoading: false,
    isExportGlobalAdminLogsDialogOpen: false,
};
const [showToast, hideToast] = toast();

export const globalAdminLogsSlice = createSlice({
    name: "globalAdminLogs",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        clearFormState: state => {
            state.formState = initialState.formState;
        },
        setIsLoadingGlobalAdminLogsExport: (state, action) => {
            state.isExportGlobalAdminLogsLoading = action.payload;
        },
        setIsDialogOpenGlobalAdminLogsExport: (state, action) => {
            state.isExportGlobalAdminLogsDialogOpen = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setPage,
    setPageToNull,
    setFormState,
    clearFormState,
    setIsLoadingGlobalAdminLogsExport,
    setIsDialogOpenGlobalAdminLogsExport,
    setSortColumn,
} = globalAdminLogsSlice.actions;

export const selectIsLoading = state => {
    return state.globalAdminLogs.isLoading;
};
export const selectPage = state => {
    return state.globalAdminLogs.page;
};
export const selectFormState = state => {
    return state.globalAdminLogs.formState;
};
export const selectIsExportGlobalAdminLogsDialogOpen = state => {
    return state.globalAdminLogs.isExportGlobalAdminLogsDialogOpen;
};

export const selectIsExportGlobalAdminLogsLoading = state => {
    return state.globalAdminLogs.isExportGlobalAdminLogsLoading;
};
export const selectSortColumn = state => {
    return state.globalAdminLogs.sortColumn;
};
export const fetchGlobalAdminLogs = (top, skip, paginateDetails) => async (dispatch, getState) => {
    const { isLoading, formState, sortColumn } = getState().globalAdminLogs;
    const { rowsPerPage } = getState().session;
    let apiURI;
    top = !top ? rowsPerPage : top;
    const { fromDate, toDate, adminLogType } = formState;
    let isDefaultMatrixLog = false;
    let queryString = "";
    const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    if (fromDate) {
        queryString += `created >= '${fromDate}'`;
    }
    if (toDate) {
        if (queryString) {
            queryString += " and ";
        }
        queryString += `created <= '${toDate} 23:59:59'`;
    }
    if (adminLogType) {
        if (adminLogType === "Default Exemption Matrix") {
            isDefaultMatrixLog = true;
        } else {
            if (queryString) {
                queryString += " and ";
            }
            queryString += `objectType = '${adminLogType}'`;
        }
    }
    if (isLoading) {
        return;
    }
    dispatch(setIsLoading(true));
    if (queryString) {
        apiURI = buildApiV3UrlWithQuery(
            `globalAdminLogs?isDefaultMatrixLog=${isDefaultMatrixLog}`,
            queryString,
            null,
            top,
            skip,
            sort
        );
    } else {
        apiURI = buildApiV3UrlWithQuery(`globalAdminLogs`, null, null, top, skip, sort);
    }
    const apiResponse = await axios.get(`${apiURI}`, {
        withCredentials: true,
    });

    if (!parseSuccessResponse(CRUDOperation.ReadAll, apiResponse)?.recordFound) {
        dispatch(setPageToNull());
    } else {
        const readAllResponse = parseGetAllObjResponse(apiResponse);

        const paginateData = paginateDetails
            ? {
                  pageNumber: paginateDetails?.pageNumber,
                  startIndex: paginateDetails?.startIndex,
                  endIndex: paginateDetails?.endIndex,
                  rowsPerPage: paginateDetails?.rowsPerPage,
                  totalRecords: readAllResponse?.count,
              }
            : {
                  pageNumber: 1,
                  startIndex: 0,
                  endIndex: 19,
                  rowsPerPage: 20,
                  totalRecords: readAllResponse?.count,
              };
        dispatch(
            setPage({
                data: readAllResponse.value,
                paginate: paginateData,
            })
        );
    }

    dispatch(setIsLoading(false));
};

export const postGlobalAdminLogsExportAPI = title => async (dispatch, getState) => {
    const { isExportGlobalAdminLogsLoading, formState, sortColumn } = getState().globalAdminLogs;
    const { fromDate, toDate, adminLogType } = formState;
    if (isExportGlobalAdminLogsLoading) {
        return;
    }
    const orderBy = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;

    dispatch(setIsLoadingGlobalAdminLogsExport(true));
    const response = await axios.post(
        buildApiV3Url(`export-global-admin-logs`),
        {
            title,
            fromDate,
            toDate,
            adminLogType,
            orderBy,
        },
        { withCredentials: true }
    );
    dispatch(setIsDialogOpenGlobalAdminLogsExport(false));
    dispatch(setIsLoadingGlobalAdminLogsExport(false));

    if (response.status === 200) {
        const baseURL = `https://${docLockerClient}`;
        window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
        showToast(
            "success",
            "We are working on your export. When it's ready, find it in",
            "export-success",
            true,
            "your downloads",
            () => {
                hideToast("export-success");
                window.open(`${baseURL}/mylockers/downloads`, "_blank", "noopener,noreferrer");
            },
            "The time it takes to export data depends on the size of the file and network traffic."
        );
        return;
    }
    showToast("error", UnkownError);
};

export default globalAdminLogsSlice.reducer;
