import { buildApiV3Url, buildApiV3UrlWithQuery, matchSubscriptions } from "../../shared/Utils";
import featureFlag from "../../featureToggler/featureFlag";
import { getStoreValues } from "../commonSlice";
import axios from "../../axios";
import {
    fetchCertificateCommunicationTaxTypesAsync,
    fetchTemplateCommunicationTaxTypesAsync,
    setIsLoadingTaxContentForms,
    setMostCommonForm,
    setTaxContentForm,
    setTaxContentForms,
    setTaxTemplateTag,
} from "./index";

const fetchTaxContentFormsAsync =
    (reason, region, existingTemplateTag, certificateID, certOnly = false) =>
    async dispatch => {
        dispatch(setTaxContentForm(""));
        dispatch(setTaxTemplateTag(""));

        if (!shouldFetchTaxForms(region, reason, dispatch)) return false;

        dispatch(setIsLoadingTaxContentForms(true));

        const { regionParam, matrixParam, regionInitials } = buildRegionParams(region);

        const taxContentFormValues = await fetchTaxContentForms(
            regionParam,
            certOnly,
            regionInitials,
            dispatch
        );
        const templateTags = await fetchTemplateTags(reason, region, matrixParam);

        const foundForm = findForm(taxContentFormValues, templateTags);
        const foundFormExisting = existingTemplateTag
            ? findForm(taxContentFormValues, [existingTemplateTag])
            : null;

        dispatchTaxFormState(foundForm, foundFormExisting, dispatch);

        if (certificateID) {
            dispatch(fetchCertificateCommunicationTaxTypesAsync(certificateID));
        } else if (foundForm) {
            dispatch(
                fetchTemplateCommunicationTaxTypesAsync(
                    `?$filter=templateTag eq '${foundForm.tag}'&$top=1000`
                )
            );
        }

        dispatch(setIsLoadingTaxContentForms(false));
        return true;
    };

const shouldFetchTaxForms = (region, reason, dispatch) => {
    // Fetch form and taxType data only if account has the following subscriptions
    // 1. ECMProComms
    // 2. ECMPremiumComms
    const hasValidSubscription = matchSubscriptions(
        featureFlag.afcContent.associateTaxTypeData.subscription,
        dispatch(getStoreValues("session.subscriptions"))
    );

    return hasValidSubscription && region;
};

const buildRegionParams = region => {
    let regionParam = region[0].name;
    let matrixParam = `'${region[0].name}'`;
    let regionInitials = region[0].initials;

    if (region.length > 1) {
        regionParam = region.map(r => r.name).toString();
        matrixParam = `'${regionParam.split(",").join("','")}'`;
        regionInitials = region
            .map(r => r.initials)
            .toString()
            .split(",")
            .join("','");
    }

    return { regionParam, matrixParam, regionInitials };
};

const fetchTaxContentForms = async (regionParam, certOnly, regionInitials, dispatch) => {
    const response = await axios.get(
        buildApiV3Url(
            `tax-content/certificate-forms?exposureZoneName=${regionParam}&certOnlyForms=${certOnly}`
        ),
        {
            withCredentials: true,
        }
    );

    const responseStateForms = await axios.get(
        buildApiV3UrlWithQuery(`tax-content/state-forms`, `region in ('${regionInitials}')`),
        {
            withCredentials: true,
        }
    );

    let taxContentFormValues = response?.data || [];
    if (responseStateForms?.data?.value?.[0]) {
        taxContentFormValues = taxContentFormValues.concat(responseStateForms.data.value);
    }

    dispatch(setTaxContentForms([taxContentFormValues]));

    return taxContentFormValues;
};

const fetchTemplateTags = async (reason, region, matrixParam) => {
    const filterString = `region.documentType.name eq 'Sales and Use Tax' AND region.name in (${matrixParam}) AND exemptReason.${
        reason.id ? "Id" : "Name"
    } eq '${reason.id || reason.name}'`;
    const collectionURI = buildApiV3UrlWithQuery(
        `exemption-matrix/default-matrix`,
        filterString,
        "",
        region.length,
        0,
        ""
    );

    const collectionResponse = await axios.get(collectionURI, { withCredentials: true });
    return collectionResponse?.data?.value?.map(c => c.templateTag) || [];
};

const findForm = (taxContentFormValues, templateTags) => {
    return taxContentFormValues.find(o => templateTags.includes(o.tag)) || null;
};

const dispatchTaxFormState = (foundForm, foundFormExisting, dispatch) => {
    if (foundForm) {
        dispatch(setMostCommonForm(foundForm.tag));
        dispatch(setTaxContentForm(foundForm.description));
        dispatch(setTaxTemplateTag(foundForm.tag));
    }

    if (foundFormExisting) {
        dispatch(setTaxContentForm(foundFormExisting.description));
        dispatch(setTaxTemplateTag(foundFormExisting.tag));
    }
};

export default fetchTaxContentFormsAsync;
