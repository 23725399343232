import { URLToPageNameMap } from "./constants";
import { getURLConstantsKey } from "./Utils";

export default function adobeAnalytics(
    location,
    subscriptions,
    userId,
    accountId,
    isAuthenticated
) {
    const urlMatch = getURLConstantsKey(location.pathname);
    const pathnameKey = urlMatch ? `/${urlMatch[1]}/#` : location.pathname;
    const pageName = URLToPageNameMap[pathnameKey];
    const categories = pageName ? pageName.split("-") : [];
    const category = categories[0] ? categories[0] : "";
    const subCategory1 = categories[1] ? categories[1] : "";
    const subCategory2 = categories[2] ? categories[2] : "";
    const identifiers = location.pathname ? location.pathname.split("/") : [];
    const identifier = identifiers[2] ? identifiers[2] : "";

    window.digitalData = {
        page: {
            pageInfo: {
                pageName: pageName ? `ecm | ${pageName}` : "ecm",
                channel: "ECM",
                category,
                subCategory1,
                subCategory2,
                identifier,
                language: "US_EN",
                Platform: "DESKTOP",
                subscriptions: subscriptions?.join(),
            },
        },
        user: {
            profileInfo: {
                isLoggedIn: isAuthenticated ? "YES" : "NO",
                profileID: `${userId}`,
                accountID: `${accountId}`,
            },
        },
    };

    // add virtual page load trigger
    /* eslint no-underscore-dangle: ["error", { "allow": ["_satellite"] }] */
    if (window._satellite) window._satellite.track("page_view");
}
