import axios from "../../axios";
import {
    buildApiV3Url,
    fixSearchStringForPostgres,
    transformExemptionData,
} from "../../shared/Utils";
import {
    reloadCertificateStatuses,
    setFormState,
    setLoading,
    setNoDataType,
    setPage,
    setPageToNull,
} from ".";
import { CertificateStatuses, completeRequestStatuses } from "../../shared/constants";

// Constants
const STATUS_MAPPINGS = {
    COMPLETE: completeRequestStatuses,
    "PENDING-FUTURE": [CertificateStatuses.PendingFuture],
};

// Helper functions
const getExemptionStatuses = (certificateStatus, statuses) => {
    const exemptionStatuses = [];

    Object.entries(STATUS_MAPPINGS).forEach(([statusName, mappedStatuses]) => {
        const hasMatchingStatus = mappedStatuses.some(status =>
            certificateStatus?.includes(status)
        );

        if (hasMatchingStatus) {
            const matchingStatus = statuses?.find(status => status?.name === statusName);
            if (matchingStatus?.id) {
                exemptionStatuses.push(matchingStatus.id);
            }
        }
    });

    return exemptionStatuses;
};

const createRequestPayload = params => {
    const {
        pageSize,
        pageNumber,
        sortColumn,
        searchVal,
        certificateFiltersState,
        exemptionStatuses,
    } = params;

    return {
        pageSize,
        pageNumber,
        orderBy: sortColumn[0],
        orderByDirection: sortColumn[1] ? "DESC" : "ASC",
        nameOrCode: fixSearchStringForPostgres(searchVal),
        purchaseOrderNumber: certificateFiltersState.invoiceNumber,
        isPrimary: certificateFiltersState.isCertificateActive,
        isBillTo: certificateFiltersState.isBillToRelation,
        isShipTo: certificateFiltersState.isShipToRelation,
        certificateImageExists: certificateFiltersState.isCertificateImage,
        ...getDateRangeFields(certificateFiltersState),
        status: certificateFiltersState.certificateStatus,
        exemptionStatus: exemptionStatuses,
        exposureZones: certificateFiltersState.certificateRegions,
        exemptionReasons: certificateFiltersState.exemptReasons,
        customerRegions: certificateFiltersState.customerRegions,
        ...getFormattedIds(certificateFiltersState),
        certificateCustomFields: certificateFiltersState.certificateCustomFields,
    };
};

const getDateRangeFields = filters => ({
    customerCreatedFrom: filters.customerCreatedDateAfter || null,
    customerCreatedTo: filters.customerCreatedDateBefore || null,
    customerModifiedFrom: filters.customerModifiedDateAfter || null,
    customerModifiedTo: filters.customerModifiedDateBefore || null,
    certificateCreatedFrom: filters.certificateCreatedDateAfter || null,
    certificateCreatedTo: filters.certificateCreatedDateBefore || null,
    certificateModifiedFrom: filters.certificateModifiedDateAfter || null,
    certificateModifiedTo: filters.certificateModifiedDateBefore || null,
    certificateEffectiveFrom: filters.certificateEffectiveDateAfter || null,
    certificateEffectiveTo: filters.certificateEffectiveDateBefore || null,
    certificateExpirationFrom: filters.certificateExpirationDateAfter || null,
    certificateExpirationTo: filters.certificateExpirationDateBefore || null,
});

const getFormattedIds = filters => ({
    certificateIds: filters.certificateIds?.length
        ? filters.certificateIds
              .split("\n")
              .map(value => fixSearchStringForPostgres(value.trim()))
              .map(Number)
        : [],
    customerCodes: filters.customerCodes?.length
        ? filters.customerCodes.split("\n").map(value => fixSearchStringForPostgres(value.trim()))
        : [],
});

const fetchVendorCertificatesSearchAPI =
    (onLanding, paginateDetails, pageSize, pageNumber = 1) =>
    async (dispatch, getState) => {
        const { isloading, formState, certificateFiltersState, sortColumn, certificateStatuses } =
            getState().certificate;
        const { rowsPerPage } = getState().session;

        // Handle loading state
        if (isloading) {
            dispatch(
                setFormState({
                    ...formState,
                    nameOrCodeWhileLoading: formState.nameOrCode,
                })
            );
            return;
        }

        try {
            dispatch(setLoading(true));

            // Get certificate statuses
            await reloadCertificateStatuses(dispatch);
            const statuses = localStorage.getItem("certificateStatuses")
                ? JSON.parse(localStorage.getItem("certificateStatuses"))
                : certificateStatuses;

            // Prepare request parameters
            const effectivePageSize = pageSize || rowsPerPage;
            const searchVal = formState.nameOrCodeWhileLoading || formState.nameOrCode;
            const exemptionStatuses = getExemptionStatuses(
                certificateFiltersState?.certificateStatus,
                statuses
            );

            // Make API request
            const apiResponse = await axios.post(
                buildApiV3Url(`certificates/vendor-search`),
                createRequestPayload({
                    pageSize: effectivePageSize,
                    pageNumber,
                    sortColumn,
                    searchVal,
                    certificateFiltersState,
                    exemptionStatuses,
                }),
                { withCredentials: true }
            );

            // Handle response
            handleApiResponse(apiResponse, dispatch, {
                onLanding,
                effectivePageSize,
                pageNumber,
                paginateDetails,
                formState,
            });
        } catch (error) {
            dispatch(setNoDataType(onLanding ? "onLanding" : "onSearch"));
        } finally {
            dispatch(setLoading(false));
        }
    };

const handleApiResponse = (apiResponse, dispatch, options) => {
    const { onLanding, effectivePageSize, pageNumber, paginateDetails, formState } = options;

    if (!apiResponse.data) {
        dispatch(setNoDataType(onLanding ? "onLanding" : "onSearch"));
        dispatch(setPageToNull(effectivePageSize));
        return;
    }

    const response = transformExemptionData(apiResponse, false, false, [
        "expectedTaxCodeName",
        "taxCodeName",
    ]);

    const paginateData = paginateDetails || {
        pageNumber,
        startIndex: 0,
        endIndex: 19,
        rowsPerPage: effectivePageSize,
        totalRecords: response.data.count,
    };

    dispatch(
        setPage({
            data: response.data.value,
            paginate: paginateData,
        })
    );

    if (formState.nameOrCodeWhileLoading) {
        dispatch(
            setFormState({
                ...formState,
                nameOrCodeWhileLoading: "",
            })
        );
    }
};

export default fetchVendorCertificatesSearchAPI;
