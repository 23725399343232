import { SCol, SRow } from "@avalara/skylab-react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import featureFlag from "../../featureToggler/featureFlag";
import Protected from "./ProtectedRoutes";
import { retailRoutes } from "./RetailRouteConfig";
import {
    advanceSubscription,
    ecmRole,
    ecmSubscription,
    lowerSubscription,
    subscription,
    userRoleAdminSubscription,
    vendorAvaTaxAPSubscription,
    vendorPremiumOnlySubscription,
    vendorSubscription,
    customerSubscription,
} from "./Subscription";
import OutgoingCertificateContext from "../settings/customFields/certificatesCustomFields/OutgoingCertificateContext";

const ImportCustomers = React.lazy(() => import("../bulkImport/ImportCustomers"));
const ImportVendors = React.lazy(() => import("../bulkImport/ImportVendors"));
const Campaign = React.lazy(() => import("../campaigns/Campaign"));

const CertificateForms = React.lazy(() => import("../content/CertificateForms/CertificateForms"));
const ExemptEntities = React.lazy(() => import("../content/ExemptEntities/ExemptEntities"));
const TaxAnswers = React.lazy(() => import("../content/TaxAnswers/TaxAnswers"));
const TaxLaws = React.lazy(() => import("../content/TaxLaws/TaxLaws"));
const CoverLetter = React.lazy(() => import("../coverLetters/CoverLetter"));
const CoverLetterGrid = React.lazy(() => import("../coverLetters/CoverLetterGrid"));
const CoverLetterHeader = React.lazy(() => import("../coverLetters/CoverLetterHeader"));
const VendorLetterGrid = React.lazy(() => import("../coverLetters/VendorLetterGrid"));
const Customer = React.lazy(() => import("../customers/Customer"));
const CustomerHeader = React.lazy(() => import("../customers/CustomerHeader"));
const HealthCheck = React.lazy(() => import("../healthCheck/HealthCheck"));
const Overview = React.lazy(() => import("../overview/Overview"));
const Resources = React.lazy(() => import("../overview/resources/Resources"));
const Promo = React.lazy(() => import("../provision/Promo"));
const RequestDashboard = React.lazy(() => import("../requests/RequestDashboard"));
const Requests = React.lazy(() => import("../requests/Requests"));
const BulkRequest = React.lazy(() => import("../requests/bulkRequest/BulkRequest"));
const SingleRequest = React.lazy(() => import("../requests/singleRequest/SingleRequest"));
const VendorRequest = React.lazy(() => import("../requests/vendorRequests/VendorRequest"));
const ValidationHistory = React.lazy(() => import("../review/ValidationHistory"));
const Certificate = React.lazy(() => import("../review/certificate/Certificate"));
const CertificateDetail = React.lazy(() => import("../review/certificate/CertificateDetail"));
const Validation = React.lazy(() => import("../review/validation/Validation"));
const ValidationDetails = React.lazy(() => import("../review/validation/ValidationDetails"));
const RoleManagementPage = React.lazy(() => import("../roleManagementPage/RoleManagementPage"));

const BulkUploadCertificates = React.lazy(() =>
    import("../search/BulkUpload/BulkUploadCertificates")
);
const SavedSearches = React.lazy(() => import("../search/SavedSearches/SavedSearches"));
const VendorCertificates = React.lazy(() =>
    import("../search/VendorCertificates/VendorCertificate")
);
const VendorCertificateHeader = React.lazy(() =>
    import("../search/VendorCertificates/VendorCertificateHeader")
);
const SendPackage = React.lazy(() => import("../search/VendorPackages/SendPackage"));
const VendorPackageContent = React.lazy(() =>
    import("../search/VendorPackages/VendorPackageContent")
);
const VendorPackageCreate = React.lazy(() =>
    import("../search/VendorPackages/VendorPackageCreate")
);
const VendorPackageEdit = React.lazy(() => import("../search/VendorPackages/VendorPackageEdit"));
const Certificates = React.lazy(() => import("../search/certificates/Certificates"));
const Customers = React.lazy(() => import("../search/customers/Customers"));
const PendingSearch = React.lazy(() => import("../search/pending/PendingSearch"));
const Vendors = React.lazy(() => import("../search/vendors/Vendors"));

const ManageExemptionReasons = React.lazy(() =>
    import("../settings/ManageExemptionReasons/ManageExemptionReasons")
);
const Settings = React.lazy(() => import("../settings/Setting"));
const CustomFields = React.lazy(() => import("../settings/customFields/CustomFields"));
const CertificatesCustomFields = React.lazy(() =>
    import("../settings/customFields/certificatesCustomFields/CertificatesCustomFields")
);
const CustomersCustomFields = React.lazy(() =>
    import("../settings/customFields/customersCustomFields/CustomersCustomFields")
);
const ManageInvalidReasons = React.lazy(() =>
    import("../settings/invalidReason/ManageInvalidReasons")
);
const Labels = React.lazy(() => import("../settings/labels/Labels"));
const CertificateLabels = React.lazy(() =>
    import("../settings/labels/certificatesLabels/CertificateLabels")
);
const CustomerLabels = React.lazy(() =>
    import("../settings/labels/customersLabels/CustomerLabels")
);
const Region = React.lazy(() => import("../settings/region/Region"));
const Retail = React.lazy(() => import("../settings/retail/Retail"));
const RetailLocations = React.lazy(() =>
    import("../settings/retail/retailLocations/RetailLocations")
);
const RetailSettings = React.lazy(() => import("../settings/retail/retailSettings/RetailSettings"));
const AdvancedSettings = React.lazy(() => import("../settings/tabs/AdvancedSettings"));
const CertExpressSettings = React.lazy(() => import("../settings/tabs/CertExpressSettings"));
const CompanyGroupsSettings = React.lazy(() =>
    import("../settings/companyGroups/CompanyGroupsSettings")
);
const CertificateOutgoingRequestFormsFilter = React.lazy(() =>
    import("../settings/tabs/CertificateRequestForms/CertificateOutgoingRequestFormsFilter")
);
const CertificateOutgoingRequestFormsGrid = React.lazy(() =>
    import("../settings/tabs/CertificateRequestForms/CertificateOutgoingRequestFormsGrid")
);
const CertificateRequestForms = React.lazy(() =>
    import("../settings/tabs/CertificateRequestForms/CertificateRequestForms")
);
const CertificateRequestFormsContent = React.lazy(() =>
    import("../settings/tabs/CertificateRequestForms/CertificateRequestFormsContent")
);
const CertificateRequestFormsFilter = React.lazy(() =>
    import("../settings/tabs/CertificateRequestForms/CertificateRequestFormsFilter")
);
const CertificateRequestFormsGrid = React.lazy(() =>
    import("../settings/tabs/CertificateRequestForms/CertificateRequestFormsGrid")
);
const CertificateRequests = React.lazy(() => import("../settings/tabs/CertificateRequests"));
const General = React.lazy(() => import("../settings/tabs/General"));
const InternalUserSettings = React.lazy(() => import("../settings/tabs/InternalUserSettings"));
const ManageProductCategories = React.lazy(() =>
    import("../settings/productCategories/ManageProductCategories")
);

const ECMReports = React.lazy(() => import("../ECMReports/ECMReports"));
const AdminReports = React.lazy(() => import("../ECMReports/AdminReports"));

const routes = (setHideHeader, setHideNav) => [
    {
        id: uuidv4(),
        path: "/",
        element: (
            <Protected
                conditional="homeComponent"
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}
            />
        ),
    },
    {
        id: uuidv4(),
        path: "/overview",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Overview />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/settings",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={[
                    ecmRole.CEMAdmin,
                    ecmRole.CEMManager,
                    ecmRole.CEMCertificateReviewer,
                    ecmRole.CEMViewer,
                    ecmRole.CEMPreValidator,
                ]}
                internalUser
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Settings />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <General />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "general",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <General />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "certificateRequests",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificateRequests />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "default-exemption-forms",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        name="form"
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificateRequestForms />
                    </Protected>
                ),
                children: [
                    {
                        index: true,
                        id: uuidv4(),
                        element: (
                            <Protected
                                conditional="certificateRequestFormsFilter"
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}
                            />
                        ),
                    },
                    {
                        id: uuidv4(),
                        path: "incoming",
                        element: (
                            <Protected
                                allowedSubscriptions={advanceSubscription}
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}>
                                <SRow>
                                    <SCol>
                                        <CertificateRequestFormsFilter />
                                        <CertificateRequestFormsGrid />
                                    </SCol>
                                </SRow>
                            </Protected>
                        ),
                    },
                    {
                        id: uuidv4(),
                        path: "outgoing",
                        element: (
                            <Protected
                                allowedSubscriptions={vendorSubscription}
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}>
                                <SRow>
                                    <SCol>
                                        <CertificateOutgoingRequestFormsFilter />
                                        <CertificateOutgoingRequestFormsGrid />
                                    </SCol>
                                </SRow>
                            </Protected>
                        ),
                    },
                ],
            },

            {
                id: uuidv4(),
                path: "certexpress-settings",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertExpressSettings />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "advanced-settings",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <AdvancedSettings />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "internal-user-settings",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <InternalUserSettings />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/promo",
        element: <Promo />,
    },
    {
        id: uuidv4(),
        path: "settings/manage-regions",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={featureFlag.settings.viewExemptionRegion.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Region />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "settings/custom-fields",
        element: (
            <Protected
                allowedSubscriptions={[subscription.ECMPremium, subscription.VEPREMIUM]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CustomFields />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                path: "certificates-custom-fields/incoming",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, subscription.VEPREMIUM]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <OutgoingCertificateContext.Provider value={false}>
                            <CertificatesCustomFields />
                        </OutgoingCertificateContext.Provider>
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "certificates-custom-fields/outgoing",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, subscription.VEPREMIUM]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <OutgoingCertificateContext.Provider value>
                            <CertificatesCustomFields />
                        </OutgoingCertificateContext.Provider>
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "customers-custom-fields",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, subscription.VEPREMIUM]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CustomersCustomFields />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "settings/retail",
        element: (
            <Protected
                allowedSubscriptions={featureFlag.settings.retailLocations.subscription}
                allowedCEMRoles={featureFlag.settings.viewRetailLocations.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Retail />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                path: "locations",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.retailLocations.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailLocations />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "retail-settings",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.retailLocations.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailSettings />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "settings/labels",
        element: (
            <Protected
                allowedSubscriptions={featureFlag.settings.viewLabels.subscription}
                allowedCEMRoles={featureFlag.settings.viewLabels.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Labels />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                path: "certificate-labels",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.viewLabels.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificateLabels />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "customer-labels",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.viewLabels.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CustomerLabels />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "settings/companyGroups",
        element: (
            <Protected
                allowedSubscriptions={featureFlag.settings.companyGroups.subscription}
                allowedCEMRoles={featureFlag.settings.companyGroups.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CompanyGroupsSettings />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/customers",
        element: (
            <Protected
                allowedSubscriptions={[
                    ...lowerSubscription,
                    ...advanceSubscription,
                    ...vendorAvaTaxAPSubscription,
                ]}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendors",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Vendors />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/customer/:id",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CustomerHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/vendor/:id",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CustomerHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/certificates",
        element: (
            <Protected
                allowedSubscriptions={[
                    ...lowerSubscription,
                    ...ecmSubscription,
                    ...vendorAvaTaxAPSubscription,
                ]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Certificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/pending",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <PendingSearch />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/add-customer",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customer setVendor={false} />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/add-vendor",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customer setVendor />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/review",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Validation />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: "true",
                element: (
                    <Protected
                        allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                        allowedRETRoles={[ecmRole.RETManager]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <ValidationDetails />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "history/:certificateID",
                element: (
                    <Protected
                        allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                        allowedRETRoles={[ecmRole.RETManager]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <ValidationHistory />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: ":certificateID",
                element: (
                    <Protected
                        allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                        allowedRETRoles={[ecmRole.RETManager]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <ValidationDetails />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/certificate",
        element: (
            <Protected
                allowedSubscriptions={[
                    ...lowerSubscription,
                    ...ecmSubscription,
                    ...vendorSubscription,
                ]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Certificate />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: "true",
                element: <CertificateDetail />,
            },
            {
                id: uuidv4(),
                path: "history/:certificateID",
                element: <ValidationHistory />,
            },
            {
                id: uuidv4(),
                path: ":certificateID",
                element: <CertificateDetail />,
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/import-customers",
        element: (
            <Protected
                allowedSubscriptions={customerSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ImportCustomers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/import-vendors",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ImportVendors />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/requests/create",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Requests />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/requests",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <RequestDashboard />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <BulkRequest />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "singleRequests",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <SingleRequest />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "bulkRequests",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <BulkRequest />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/campaigns/:campaignID",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Campaign />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/tax-answers",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <TaxAnswers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/tax-laws",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <TaxLaws />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/exempt-entities",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ExemptEntities />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/certificate-forms",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CertificateForms />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Resources />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/customers",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/certificates",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Certificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/vendor-certificates",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorCertificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/vendors",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Vendors />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/certificate-forms",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CertificateRequestFormsContent />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/vendor-requests/:id/",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorRequest />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-certificates",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorCertificateHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorCertificateHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages/create",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorPackageCreate />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages/add-certificates/:id",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorPackageEdit />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages/:id",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorPackageContent />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/send-package/:id",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <SendPackage />
            </Protected>
        ),
    },

    {
        id: uuidv4(),
        path: "/add-cover-letter",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter />
            </Protected>
        ),
    },

    {
        id: uuidv4(),
        path: "/add-vendor-letter",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorPremiumOnlySubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter isVendor />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/cover-letter/:id",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter isVendor={false} />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/vendor-letter/:id",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter isVendor />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/bulk-upload",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <BulkUploadCertificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/user-role-management/:userId",
        element: (
            <Protected
                allowedSubscriptions={userRoleAdminSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <RoleManagementPage />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "settings/manage-exempt-reasons",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={featureFlag.settings.viewExemptionReason.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ManageExemptionReasons />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "health-check",
        element: <HealthCheck />,
    },
    {
        id: uuidv4(),
        path: "settings/manage-invalid-reasons",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={featureFlag.settings.viewInvalidReason.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ManageInvalidReasons />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "settings/manage-product-categories",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={featureFlag.settings.viewProductCategories.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ManageProductCategories />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "reports",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ECMReports />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <SavedSearches />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "saved-searches",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <SavedSearches />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "admin-reports",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription]}
                        allowedCEMRoles={[ecmRole.CEMAdmin]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <AdminReports />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "cover-letters",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetterHeader />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CoverLetterGrid />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "requests",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CoverLetterGrid />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "vendors",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <VendorLetterGrid />
                    </Protected>
                ),
            },
        ],
    },
    retailRoutes(setHideHeader, setHideNav),
    {
        id: uuidv4(),
        path: "*",
        element: <Protected setHideHeader={setHideHeader} setHideNav={setHideNav} />,
    },
];

export default routes;
