import { createSlice } from "@reduxjs/toolkit";
import { buildApiV3Url, buildApiV3UrlWithQuery } from "../shared/Utils";
import axiosInstanceWithoutInterceptor from "../axiosWithoutInterceptor";
import { daysOfTheWeek, ExportTypes } from "../shared/constants";
import axios from "../axios";
import toast from "../hooks/toast";

export const savedSearchesSlice = createSlice({
    name: "savedSearches",
    initialState: {
        isloading: false,
        isPostloading: false,
        savedSearchesState: {
            aiUserId: 0,
            cannedSearch: "",
            searchType: "",
            title: "",
            criteria: "",
        },
        page: {
            data: [],
            paginate: {
                pageNumber: 0,
                startIndex: -1,
                endIndex: 0,
                rowsPerPage: 0,
                totalRecords: 0,
            },
        },
        sortColumn: ["title", true],
        formState: {
            nameOrCode: "",
        },
        noDataType: "",
        savedSearches: [],
        isSavedSearchDialog: false,
        isScheduleExportDialog: false,
        responseType: "",
        isVendor: false,
        daysOfTheWeek,
    },

    reducers: {
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageToNull: state => {
            state.page = {
                data: [],
                paginate: {
                    pageNumber: 0,
                    startIndex: -1,
                    endIndex: 0,
                    rowsPerPage: 0,
                    totalRecords: 0,
                },
            };
        },
        setNoDataType: (state, action) => {
            state.noDataType = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setFormState: (state, action) => {
            state.formState = action.payload;
        },
        setSavedSearches: (state, action) => {
            state.savedSearches = action.payload;
        },
        setSavedSearchesState: (state, action) => {
            Object.keys(action.payload).forEach(key => {
                state.savedSearchesState[key] = action.payload[key];
            });
        },
        setIsSavedSearchDialog: (state, action) => {
            state.isSavedSearchDialog = action.payload;
        },
        setIsScheduleExportDialog: (state, action) => {
            state.isScheduleExportDialog = action.payload;
        },
        setResponseType: (state, action) => {
            state.responseType = action.payload;
        },
        setPostLoading: (state, action) => {
            state.isPostloading = action.payload;
        },
        setIsVendor: (state, action) => {
            state.isVendor = action.payload;
        },
    },
});

export const {
    setLoading,
    setPage,
    setPageToNull,
    setSortColumn,
    setNoDataType,
    setFormState,
    setSavedSearches,
    setIsSavedSearches,
    setSavedSearchesState,
    setIsSavedSearchDialog,
    setIsScheduleExportDialog,
    setResponseType,
    setPostLoading,
    setIsVendor,
} = savedSearchesSlice.actions;

export const selectIsVendor = state => {
    return state.savedSearches.isVendor;
};
export const selectPage = state => {
    return state.savedSearches.page;
};

export const selectSortColumn = state => {
    return state.savedSearches.sortColumn;
};

export const selectIsLoading = state => {
    return state.savedSearches.isloading;
};

export const selectFormState = state => {
    return state.savedSearches.formState;
};

export const selectNoData = state => {
    return state.savedSearches.noDataType;
};

export const selectSavedSearches = state => {
    return state.savedSearches.savedSearches;
};

export const selectSavedSearchesState = state => {
    return state.savedSearches.savedSearchesState;
};

export const selectIsSavedSearchDialog = state => {
    return state.savedSearches.isSavedSearchDialog;
};

export const selectIsScheduleExportDialog = state => {
    return state.savedSearches.isScheduleExportDialog;
};

export const selectResponseType = state => {
    return state.savedSearches.responseType;
};

export const selectIsPostloading = state => {
    return state.savedSearches.isPostloading;
};

export const selectDaysOfTheWeek = state => {
    return state.savedSearches.daysOfTheWeek;
};

const [showToast] = toast();

export const fetchSavedSearchesAPI =
    (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
        const localState = getState();
        const { isloading, sortColumn, formState, isVendor } = localState.savedSearches;
        const { rowsPerPage } = getState().session;
        top = !top ? rowsPerPage : top;
        if (isloading) {
            return;
        }
        const { activeCompany } = localState.session;

        dispatch(setLoading(true));
        const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
        let filter = "";
        if (formState?.nameOrCode) {
            filter = `title like '${formState?.nameOrCode.trim()}'`;
        }

        const filterString = isVendor
            ? `Certificate,Customer,Vendor,Vendor-Certificate,${ExportTypes.PendingCertificate}`
            : `Certificate,Customer,${ExportTypes.PendingCertificate}`;

        const apiURI = buildApiV3UrlWithQuery(
            `savedsearches?companyId=${activeCompany.id}&searchType=${filterString}`,
            filter,
            "",
            top,
            skip,
            sort
        );
        const response = await axiosInstanceWithoutInterceptor.get(`${apiURI}`, {
            withCredentials: true,
        });
        if (!response?.data) {
            onLanding ? dispatch(setNoDataType("onLanding")) : dispatch(setNoDataType("onSearch"));
            dispatch(setPageToNull());
        } else {
            dispatch(setNoDataType(""));
            const paginateData = paginateDetails
                ? {
                      pageNumber: paginateDetails.currentPage,
                      startIndex: paginateDetails.startIndex,
                      endIndex: paginateDetails.endIndex,
                      rowsPerPage: paginateDetails.rowsPerPage,
                      totalRecords: response.data.count,
                  }
                : {
                      pageNumber: 1,
                      startIndex: 0,
                      endIndex: 19,
                      rowsPerPage: 20,
                      totalRecords: response.data.count,
                  };
            dispatch(
                setPage({
                    data: response.data.value,
                    paginate: paginateData,
                })
            );
            dispatch(setSavedSearches(response.data.value));
        }

        dispatch(setLoading(false));
    };

export const getSavedSearchesAsync = searchType => async (dispatch, getState) => {
    const localState = getState();
    const { isloading, sortColumn } = localState.savedSearches;

    if (isloading) {
        return false;
    }
    const { activeCompany } = localState.session;

    dispatch(setLoading(true));
    let response = null;
    const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    const apiURI = buildApiV3UrlWithQuery(
        `savedsearches?companyId=${activeCompany.id}&searchType=${searchType}`,
        "",
        "",
        200,
        "",
        sort
    );
    response = await axiosInstanceWithoutInterceptor.get(`${apiURI}`, {
        withCredentials: true,
    });

    if (response?.data?.value) {
        dispatch(setSavedSearches(response.data.value));
    } else {
        dispatch(setSavedSearches([]));
    }
    dispatch(setLoading(false));
    return true;
};

export const postSavedSearchAPI = () => async (dispatch, getState) => {
    const localState = getState();
    const { isloading, savedSearchesState } = localState.savedSearches;
    const { activeCompany } = localState.session;

    if (isloading) {
        return;
    }
    let response = null;

    dispatch(setPostLoading(true));
    const apiURI = buildApiV3UrlWithQuery(`savedsearches`, "", "", 0);
    response = await axiosInstanceWithoutInterceptor.post(
        `${apiURI}`,
        {
            aiUserId: savedSearchesState.aiUserId,
            cannedSearch: savedSearchesState.cannedSearch,
            searchType: savedSearchesState.searchType,
            title: savedSearchesState.title,
            criteria: savedSearchesState.criteria,
            avaTaxCompanyId: activeCompany.id,
        },
        {
            withCredentials: true,
        }
    );

    if (response.data) {
        dispatch(setIsSavedSearchDialog(false));
        dispatch(setResponseType("success"));
    } else {
        dispatch(setIsSavedSearchDialog(false));
        dispatch(setResponseType("failure"));
    }
    dispatch(setPostLoading(false));
};

export const putScheduleEmailAPI = putData => async (dispatch, getState) => {
    const localState = getState();
    const { isloading } = localState.savedSearches;

    if (isloading) {
        return;
    }

    dispatch(setPostLoading(true));
    const response = await axios.put(buildApiV3Url(`scheduled-email-reports`), putData, {
        withCredentials: true,
    });

    if (response.data) {
        dispatch(setIsScheduleExportDialog(false));
        dispatch(setResponseType("success"));
        dispatch(fetchSavedSearchesAPI());
        showToast("success", `Report scheduled.`, "putScheduleEmailToast", false);
    } else {
        dispatch(setIsScheduleExportDialog(false));
        dispatch(fetchSavedSearchesAPI());
        dispatch(setResponseType("failure"));
    }
    dispatch(setPostLoading(false));
};
export default savedSearchesSlice.reducer;
